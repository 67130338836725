import React from 'react';
import { useParams } from 'react-router-dom';
import { PrimaryTypography } from '../../../ui/typography/typography';
import JobPostDetails from './JobPostDetails';
import JobPostTable from './JobPostTable';

export const JobPostingView = () => {

  const { id } = useParams<{ id: string }>();

  return (
    <div className='bg-gray-100 p-2'>
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        View Job Posts
      </PrimaryTypography.FiveXLarge>
      { id ? <JobPostDetails /> : <JobPostTable /> }
    </div>
  );
};
