import React, { useState } from 'react';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import { Tabs } from '../../assessment/view/tabs/tabs';
import commonStyles from '../admin.module.css';
import { GeneralSettings } from './SettingsSections/GeneralSettings';
import OrganizationHierarchy from './SettingsSections/OrganizationHierarchy';
import { Subscription } from './SettingsSections/Subscription';

export const OrganizationSettings = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabTitles = ['Organisation Hierarchy', 'General Settings', 'Subscription', 'Advanced Settings'];

  const renderTabContent = () => {
    if (activeTab === 0) {
      return <OrganizationHierarchy/>;
    } else if (activeTab === 1)  {
      return <GeneralSettings/>;
    } else if (activeTab === 2) {
      return <Subscription/>;
    } else if (activeTab === 3) {
      return <>Advanced Settings</>;
    }
  };

  return (
    <div className={commonStyles.container}>
      <PrimaryTypography.FiveXLarge fontWeight='bold' keepDefaultMargins={true}>
        Admin
      </PrimaryTypography.FiveXLarge>
      <div className={commonStyles.contentContainer}>
        <SecondaryTypography.Medium keepDefaultMargins={true} fontWeight='bold'>
          Organization Settings
        </SecondaryTypography.Medium>
        <Tabs
            tabTitles={tabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
        { renderTabContent() }
      </div>
    </div>
  );
};
