import React, { useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import Select, { MultiValue } from 'react-select';
import { MultiSelectListItem } from '../../../../../ui/data_source_filter/MultiSelectListItem';
import { ToggleInput } from '../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { JobApplicantCSV, JobApplicantSearchRequest } from '../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import { TopicInput } from '../../../../questions/upload_file/topic_input';

type AnalyticFilterProps = {
    setStatus: (status: string[]) => void | null;
    statusValue: string[];
    searchObject: JobApplicantSearchRequest,
    setSearchObject: (jobApplicantSearchRequest: JobApplicantSearchRequest) => void,
    searchCSVObject: JobApplicantCSV,
    setSearchCSVObject: (JobApplicantCSV: JobApplicantCSV) => void,
    minMaxAge: number[];
    setMinMaxAge: (minMax: number[]) => void;
    defaultSkills: string,
    setDefaultSkills: React.Dispatch<React.SetStateAction<string>>;
    defaultJobTitles: string,
    setDefaultJobTitles: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean,
}

export const AnalyticFilter = ({
  setStatus,
  statusValue,
  searchObject,
  setSearchObject,
  searchCSVObject,
  setSearchCSVObject,
  minMaxAge,
  setMinMaxAge,
  defaultSkills,
  setDefaultSkills,
  defaultJobTitles,
  setDefaultJobTitles,
  loading,
}: AnalyticFilterProps) => {

  const [genderInput, setGenderInput] = useState<SelectOptions[]>([]);
  const [statusInput, setStatusInput] = useState<SelectOptions[]>([]);

  useEffect(() => {
    const skillsList = defaultSkills.split(':');
    const jobTitleList = defaultJobTitles.split(':');

    setSearchObject({
      ...searchObject,
      additionalSkills: defaultSkills === ''? []:skillsList,
      jobTitle: defaultJobTitles === ''? []:jobTitleList,
    });

    setSearchCSVObject({
      ...searchCSVObject,
      additionalSkills: defaultSkills === ''? []:skillsList,
      jobTitle: defaultJobTitles === ''? []:jobTitleList,
    });

  }, [defaultJobTitles, defaultSkills]);

  const convertStringToObjects = (input: string): { label: string, value: string }[] => {
    if (input === '') {
      return [];
    } else {
      const value = input.split(':').map(item => ({
        label: item,
        value: item,
      }));
      return value;
    }
  };

  const genderList = [
    {
      'value': 'Male',
      'label': 'Male',
    },
    {
      'value': 'Female',
      'label': 'Female',
    },
    {
      'value': 'Other',
      'label': 'Other',
    },
  ];

  const statusList = [
    {
      'value': 'New',
      'label': 'New',
    },
    {
      'value': 'Suitable',
      'label': 'Suitable',
    },
    {
      'value': 'Auto-Assessment',
      'label': 'Auto Assess',
    },
    {
      'value': 'Outstanding',
      'label': 'Outstanding',
    },
    {
      'value': 'Shortlisted',
      'label': 'ShortList',
    },
    {
      'value': 'Unsuccessful-Closed',
      'label': 'Unsuccessful',
    },
  ];

  const handleMultiSelectChange = (selectedValues: MultiValue<SelectOptions>) => {
    const selected = selectedValues.map(option => option.value);
    setGenderInput(selectedValues as SelectOptions[]);
    setSearchObject({
      ...searchObject,
      gender: selected,
    });
    setSearchCSVObject({
      ...searchCSVObject,
      gender: selected,
    });
  };

  const handleApplicationStatus = (selectedValues: MultiValue<SelectOptions>) => {
    const selected = selectedValues.map(option => option.value);
    const selectedStatus = selectedValues.map(option => option.label);
    setStatus(selectedStatus);
    setStatusInput(selectedValues as SelectOptions[]);
    setSearchObject({
      ...searchObject,
      applicantStatus: selected,
    });
    setSearchCSVObject({
      ...searchCSVObject,
      applicantStatus: selected,
    });
  };

  const handleIncludeAgeToggle = () => {
    setSearchObject({
      ...searchObject,
      age: {
        ...searchObject.age,
        includeUnknown: !searchObject.age.includeUnknown,
      },
    });
    setSearchCSVObject({
      ...searchCSVObject,
      age: {
        ...searchCSVObject.age,
        includeUnknown: !searchCSVObject.age.includeUnknown,
      },
    });
  };

  return (
    <div
        className='mt-12 mr-6 flex flex-col gap-4 border-b border-gray-200'
    >
      <div className={`${loading ? 'animate-pulse' : ''}`}>
        { loading ? (
          <div className='rounded-md w-[250px] h-40 bg-gray-300'>
          </div>
        ) : (
          <div>
            <SecondaryTypography.XSmall
                className='text-gray-500 pt-1'
                fontWeight='semi-bold'>
              X: Skill(s)
            </SecondaryTypography.XSmall>
            <TopicInput
                className='max-w-[250px]'
                setTopic={setDefaultSkills}
                disableSkill={true}
                result={convertStringToObjects(defaultSkills)}
            />
          </div>
        ) }
      </div>
      <div className={`${loading ? 'animate-pulse' : ''}`}>
        { loading ? (
          <div className='rounded-md w-[250px] h-10 bg-gray-300'>
          </div>
        ) : (
          <div>
            <SecondaryTypography.XSmall
                className='text-gray-500 pt-1'
                fontWeight='semi-bold'>
              Y: Experience Job Titles
            </SecondaryTypography.XSmall>
            <TopicInput
                className='max-w-[250px]'
                setTopic={setDefaultJobTitles}
                disableSkill={true}
                result={convertStringToObjects(defaultJobTitles)}
            />
          </div>
        ) }
      </div>
      <div className={`${loading ? 'animate-pulse' : ''}`}>
        { loading ? (
          <div className='rounded-md w-[250px] h-10 bg-gray-300'>
          </div>
        ) : (
          statusValue.length === 0 && (
            <div>
              <SecondaryTypography.XSmall
                  className='text-gray-500'
                  fontWeight='semi-bold'>
                Applicant Status
              </SecondaryTypography.XSmall>
              <Select
                  className='max-w-[250px]'
                  placeholder='Select Application Status'
                  name='assessmentType'
                  options={statusList}
                  onChange={handleApplicationStatus}
                  isClearable={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option: MultiSelectListItem }}
                  value={statusInput}
                  isMulti
              />
            </div>
          )
        ) }
      </div>
      <div className={`${loading ? 'animate-pulse' : ''}`}>
        { loading ? (
          <div className='rounded-md w-[250px] h-10 bg-gray-300'>
          </div>
        ) : (
          <div>
            <SecondaryTypography.XSmall
                className='text-gray-500'
                fontWeight='semi-bold'>
              Gender
            </SecondaryTypography.XSmall>
            <Select
                className='max-w-[250px]'
                placeholder='Select Gender'
                name='assessmentType'
                options={genderList}
                onChange={handleMultiSelectChange}
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: MultiSelectListItem }}
                value={genderInput}
                isMulti
            />
          </div>
        ) }
      </div>

      <SecondaryTypography.XSmall
          className='text-gray-500'
          fontWeight='semi-bold'>
        Age Group ({ minMaxAge[0] } - { minMaxAge[1] })
      </SecondaryTypography.XSmall>
      <Range
          values={minMaxAge}
          step={1}
          min={0}
          max={100}
          onChange={(newValues: number[]) => setMinMaxAge(newValues)}
          renderTrack={({ props, children }) => (
            <div
                {...props}
                className='h-1.5 w-full self-center'
                style={{
                  ...props.style,
                  background: getTrackBackground({
                    values: minMaxAge,
                    colors: ['#ccc', '#548BF4', '#ccc'],
                    min: 0,
                    max: 100,
                  }),
                }}
            >
              { children }
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
                {...props}
                className='h-6 w-6 rounded-full bg-white flex justify-center items-center shadow-md'
                style={props.style}
            >
              <div
                  className={`h-4 w-1 ${
                    isDragged ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
              />
            </div>
          )}
      />
      <ToggleInput
          toggled={searchObject.age.includeUnknown}
          handleToggle={handleIncludeAgeToggle}
          name='edit'
          informationText='Include Unknown Ages'
      />
    </div>
  );
};
