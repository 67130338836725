import classNames from 'classnames';
import React from 'react';
import actions from '../../../../../assets/images/graphics/actions.svg';
import filter from '../../../../../assets/images/graphics/filter.svg';
import reset from '../../../../../assets/images/graphics/reset.svg';
import threshold from '../../../../../assets/images/graphics/threshold.svg';
import views from '../../../../../assets/images/graphics/views.svg';
import { JobApplicantSearchRequest } from '../../../../../utils/types/JobOpenings';
import styles from '../../../../assessment/view/tabs/tabs.module.css';

type AnalysisTabProp = {
    activeTab: number,
    setActiveTab: (value: number) => void,
    setSearchObject: (jobApplicantSearchRequest: JobApplicantSearchRequest) => void,
    setMinMaxAge: (minMax: number[]) => void;
    setExperienceValue: (value: number[]) => void,
    setSkillValue: (value: number[]) => void,
}

export const AnalysisTab = ({
  activeTab,
  setActiveTab,
  setSearchObject,
  setMinMaxAge,
  setExperienceValue,
  setSkillValue,
}: AnalysisTabProp) => {

  const handleTabClick = (index: number) => {
    if (activeTab === index) {
      // The tab clicked is already active
      setActiveTab(-1);
    } else {
      // The tab clicked is not the active one, so we update the activeTab state
      setActiveTab(index);
    }
  };

  const handleSpecialTabClick = () => {
    const newSearchObject: JobApplicantSearchRequest = {
      additionalSkills: [],
      jobTitle: [],
      keywords: [],
      gender: [],
      age: {
        minAge: 0,
        maxAge: 100,
        includeUnknown: true,
      },
      applicantStatus: [],
    };

    setMinMaxAge([0, 100]);
    setExperienceValue([0, 10]);
    setSkillValue([0, 10]);
    setSearchObject(newSearchObject);
  };

  const values = ['Filter', 'Threshold', 'Views', 'Actions', ''];
  const tabTiles = [filter, threshold, views, actions, reset];

  return (
    <div
        className='mt-12 flex flex-col gap-0 border-b border-gray-200'
    >
      { tabTiles.map((item: string, index) => {
        return (
          <div
              className={classNames(
                styles.tabButton,
                { 'bg-green-100': activeTab === index }, // Add this line for background color change
              )}
              onClick={index === 4 ? handleSpecialTabClick : () => handleTabClick(index)}
              key={item}
          >
            <div className='flex'>
              <div className='flex flex-col gap-0 items-center'>
                <img
                    className='max-w-[60px] max-h-[70px]'
                    src={item}
                />
                <p className='mt-1 text-sm text-green-600'>{ values[index] }</p>
              </div>
            </div>
          </div>
        );
      }) }
    </div>
  );
};
