import React from 'react';
import { Button } from '../../../../../ui/button/button';

type StatusMessageProps = {
  status: 'disabled' | 'requiredCompletion' | 'enabled' | 'loading',
  requiredTabs?: string[],
  enabledElement?: () => void,
};

const StatusMessage = ({ status, requiredTabs, enabledElement }: StatusMessageProps) => {
  return (
    <div className='flex items-center justify-center w-full'>
      <div className='text-center p-6'>
        { status === 'disabled' && (
          <div className='flex-col flex items-center justify-center'>
            <p className='text-gray-400 text-xl font-bold mb-4'>
              Sorry, this option is disabled.
            </p>
            { enabledElement && (
              <Button
                  onClick={enabledElement}
                  variant='primary'
                  size='medium'
              >
                Enable
              </Button>
            ) }
          </div>
        ) }
        { status === 'requiredCompletion' && requiredTabs && (
          <div>
            <p className='text-green-400 text-xl font-bold mb-4'>
              Please complete the following tabs before proceeding:
            </p>
            <ul className='list-none'>
              { requiredTabs.map((tab, index) => (
                <li key={index} className='text-gray-800 text-lg'>
                  { tab }
                </li>
              )) }
            </ul>
          </div>
        ) }
        { status === 'loading' && (
          <div></div>
        ) }
      </div>
    </div>
  );
};

export default StatusMessage;
