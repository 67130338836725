import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FormattedSelectOptions } from '../../utils/types/analytics/analytics_types';
import { SelectOptions } from '../../utils/types/react_select_types';
import inputStyles from '../input/input.module.css';
import { SecondaryTypography } from '../typography/typography';
import styles from './custom_select.module.css';
import { customStyles } from './custom_select_styles';

type CustomSelectProps = {
    placeholder?: string;
    label?: string,
    name: string,
    options: SelectOptions[] | FormattedSelectOptions[] | undefined,
    value?: SelectOptions | SelectOptions[]
    onChange: any,
    onInputChange?: any,
    disabled?: boolean,
    creatable?: boolean,
    clearable?: boolean,
    isMulti?: boolean,
    components?: any,
    onCreateOption?: ((inputValue: string) => void) | undefined,
};

export const CustomSelect = ({
  label,
  name,
  options,
  value,
  onChange,
  onInputChange,
  disabled,
  creatable = false,
  clearable = false,
  isMulti,
  components,
  onCreateOption,
  placeholder = 'Search',
}: CustomSelectProps) => {
  return (
    <div>
      {
        label &&
        <label
            className={styles.selectLabel}
        >
          <SecondaryTypography.XSmall
              className={classNames(inputStyles.labelStyles, styles.infoText)}
              fontWeight='semi-bold'
          >
            { label }
          </SecondaryTypography.XSmall>
        </label>
      }
      { !creatable ?
        <Select
            placeholder={placeholder}
            defaultValue={value}
            name={name}
            options={options}
            onChange={onChange}
            onInputChange={onInputChange}
            styles={customStyles}
            isDisabled={disabled}
            // @ts-ignore
            isMulti={isMulti}
            isClearable={clearable}
            maxMenuHeight={200}
            components={components}
        />
        :
        <CreatableSelect
            defaultValue={value}
            name={name}
            options={options}
            onChange={onChange}
            styles={customStyles}
            onInputChange={onInputChange}
            isDisabled={disabled}
            // @ts-ignore
            isMulti={isMulti}
            onCreateOption={onCreateOption}
            isClearable={clearable}
        />
      }
    </div>
  );
};
