import { toast } from 'react-toastify';
import { getEmployeeList } from '../../api_service/employees';
import { EmployeeUser } from '../../types/admin_types';
import { SelectOptions } from '../../types/react_select_types';
import { debounce } from '../debounce';

const useDebouncedEmployeeSearch = () => {
  const fetchOptions = async (inputValue: string): Promise<SelectOptions[]> => {
    try {
      const response = await getEmployeeList(inputValue, 1, 10);
      if (response.ok) {
        const data = await response.json();
        return data.employee.map((employee: EmployeeUser) => ({
          value: employee.id,
          label: `${employee.user.firstName} ${employee.user.lastName}`,
        }));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
        return [];
      }
    } catch (error) {
      toast.error(`Error fetching employees: ${error}`, { position: 'bottom-center' });
      return [];
    }
  };

  // This function will be passed directly to the loadOptions prop of AsyncCustomSelect
  const loadOptionsDebounced = debounce(async (inputValue: string, callback: (options: SelectOptions[]) => void) => {
    const options = await fetchOptions(inputValue);
    callback(options);
  }, 500);

  return { loadOptionsDebounced };
};

export default useDebouncedEmployeeSearch;
