import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../../ui/modal/modal';
import styles from '../../../../assessment/create/create_assessment.module.css';

export type ModalOption = {
  value: string;
  label: string;
  modalContent: JSX.Element | ((closeModal: () => void) => React.ReactNode);
  disabled?: boolean;
};

type DropdownWithModalProps = {
  options: ModalOption[];
  disabled?: boolean
};

export const DropdownWithModal: React.FC<DropdownWithModalProps> = ({ options, disabled = false }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<ModalOption | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const resolveModalContent = () => {
    const foundOption = options.find(o => o.value === selectedValue);

    if (foundOption) {
      return typeof foundOption.modalContent === 'function' ?
        foundOption.modalContent(closeModal) :
        foundOption.modalContent;
    } else {
      return <></>;
    }
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const option = options.find((o) => o.value === value);
    setSelectedValue(value);
    setSelectedOption(option);
    setIsModalVisible(!!option);
  };

  useEffect(() => {
    return () => {
      setIsModalVisible(false);
    };
  }, []);

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedValue('');
  };

  return (
    <div className=''>
      <select
          onChange={handleSelectChange}
          className={`${disabled ? 'bg-gray-200 border-gray-200' : 'bg-primary-blue-500 border-primary-blue-500'} py-2 px-4 rounded-md text-white pr-6 border-x-8  cursor-pointer`}
          value={selectedValue}
          disabled={disabled}
      >
        <option value='' disabled>Generate Job Description</option>
        { options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            { option.label }
          </option>
        )) }
      </select>

      { selectedOption && (
        <Modal
            visible={isModalVisible}
            toggle={closeModal}
            modalContent={() => resolveModalContent()}
            customClass={styles.modalDimensions}
        />
      ) }
    </div>
  );
};

export default DropdownWithModal;
