import React, { useEffect, useState } from 'react';
import {
  AutoEvaluation,
  AutoEvaluationLabel,
  showAllTypes,
} from '../../../../../../utils/types/JobOpenings';
import AddOrRemoveListWithOperators from '../../../ui/AddOrRemoveListWithOperators';
import Collapsible from '../../../ui/CollapsibleChildren';

interface CollapsibleData {
  id: number;
  title: string;
  labels: AutoEvaluationLabel[];
  isOpen: boolean;
  stringLabels?: string[];
}

type ListFieldsProps = {
  showAll: showAllTypes;
  setShowAll: React.Dispatch<React.SetStateAction<showAllTypes>>;
  autoEvaluation: AutoEvaluation,
  setAutoEvaluation: React.Dispatch<React.SetStateAction<AutoEvaluation>>,
  disabled?: boolean,
}

const ListFields = ({
  showAll,
  setShowAll,
  autoEvaluation,
  setAutoEvaluation,
  disabled = false,
}: ListFieldsProps) => {
  const collapsibleValues = [
    { id: 1, title: 'Educations', labels: autoEvaluation ? autoEvaluation.educations : [], isOpen: true },
    { id: 2, title: 'Certifications', labels: autoEvaluation ? autoEvaluation.certifications : [], isOpen: true },
    { id: 3, title: 'Skills', labels: autoEvaluation ? autoEvaluation.skills : [], isOpen: true },
    { id: 4, title: 'School', labels: autoEvaluation ? autoEvaluation.school : [], isOpen: true },
    { id: 5, title: 'Keywords', labels: autoEvaluation ? autoEvaluation.keywords : [], isOpen: true },
  ];
  const [collapsible, setCollapsible] = useState<CollapsibleData[]>(collapsibleValues);

  useEffect(() => {
    const newIsOpenState = getNewIsOpenState(showAll);
    if (newIsOpenState === undefined) return;

    const newCollapsibleValues = collapsibleValues.map(collapsibleItem => ({
      ...collapsibleItem,
      isOpen: newIsOpenState,
    }));

    setCollapsible(prevCollapsible => {
      if (JSON.stringify(newCollapsibleValues) !== JSON.stringify(prevCollapsible)) {
        return newCollapsibleValues;
      }
      return prevCollapsible;
    });
  }, [autoEvaluation, showAll]);

  useEffect(() => {
    const updatedAutoSuitability = { ...autoEvaluation };

    collapsible.forEach((item) => {
      switch (item.title) {
        case 'Educations':
          updatedAutoSuitability.educations = item.labels;
          break;
        case 'Certifications':
          updatedAutoSuitability.certifications = item.labels;
          break;
        case 'Skills':
          updatedAutoSuitability.skills = item.labels;
          break;
        case 'School':
          updatedAutoSuitability.school = item.labels;
          break;
        case 'Keywords':
          updatedAutoSuitability.keywords = item.labels;
          break;
        default:
          break;
      }
    });

    setAutoEvaluation(updatedAutoSuitability);
  }, [collapsible]);

  const getNewIsOpenState = (showAll: showAllTypes) => {
    if (showAll === 'showAll') {
      return true;
    } else if (showAll === 'collapseAll') {
      return false;
    }
    return;
  };

  const toggleOpen = (id: number) => {
    const newCollapsible = collapsible.map(collapsible =>
      collapsible.id === id ? { ...collapsible, isOpen: !collapsible.isOpen } : collapsible,
    );
    setCollapsible(newCollapsible);
    const areAllOpen = newCollapsible.every(collapsible => collapsible.isOpen);
    const areAllClosed = newCollapsible.every(collapsible => !collapsible.isOpen);
    if (areAllOpen) {
      setShowAll('showAll');
    } else if (areAllClosed) {
      setShowAll('collapseAll');
    } else {
      setShowAll('random');
    }
  };

  const handleLabelsChange = (id: number, updatedLabels: AutoEvaluationLabel[]) => {
    setCollapsible(
      collapsible.map(collapsible =>
        collapsible.id === id ? { ...collapsible, labels: updatedLabels } : collapsible,
      ),
    );
  };

  return (
    <div>
      { collapsible.map(({ id, title, labels, isOpen }, index) => (
        <div key={id} className={`mb-4 ${index === collapsible.length - 1 ? '' : 'mb-8'}`}>
          <Collapsible
              title={title}
              isOpen={isOpen}
              toggleOpen={() => toggleOpen(id)}
          >
            <AddOrRemoveListWithOperators
                disabled={disabled}
                key={JSON.stringify(labels)}
                initialLabels={labels}
                onLabelsChange={(updatedLabels) => handleLabelsChange(id, updatedLabels)}
            />
          </Collapsible>
        </div>
      )) }
    </div>
  );
};

export default ListFields;
