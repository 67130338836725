import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { editJobApplicant, searchJobApplicant } from '../../../../utils/api_service/JobOpenings';
import { Applicant, HighlightSkill, JobApplicant, JobApplicantEditRequest } from '../../../../utils/types/JobOpenings';
import { PDFDisplay } from './PDFDisplay';

type CandidateProfileProps = {
    selectedCandidate: Applicant,
}

export const CandidateProfile = ({
  selectedCandidate,
}: CandidateProfileProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [applicant, setApplicant] = useState<JobApplicant>();
  const [loading, setLoading] = useState<boolean>(true);
  const [editObject, setEditObject] = useState<JobApplicantEditRequest>({
    firstName: '',
    lastName: '',
    email: '',
    age: null,
    skills: [],
    gender: '',
    applicantStatus: '',
  });
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getJobApplicants = async () => {
      setLoading(true);
      try {
        const response = await searchJobApplicant(id, selectedCandidate.userId);
        const responseData = await response.json();
        setApplicant(responseData);

      } catch (error) {
        toast.error('Error Loading Job Applicants', { position: 'bottom-center' });
      } finally {
        setLoading(false); // End loading
      }
    };
    getJobApplicants();
  }, [selectedCandidate, editObject]);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const formatDate = (timestamp: number): string | null => {
    if (timestamp === 0 || timestamp === undefined){
      return null;
    } else {
      const date = new Date(timestamp * 1000); // Convert to milliseconds
      return date.toLocaleDateString(undefined, { year: 'numeric', month: 'short' });
    }
  };

  const getDifferenceInDays = (timestamp1: number | undefined, timestamp2: number | undefined): number | null => {
    if (timestamp1 === null || timestamp1 === undefined || timestamp2 === null || timestamp2 === undefined) {
      return null;
    }

    // Convert timestamps to Date objects
    const date1 = new Date(timestamp1 * 1000);
    const date2 = new Date(timestamp2 * 1000);

    // Calculate the difference in milliseconds
    const differenceInMs = date2.getTime() - date1.getTime();

    // Convert the difference to days
    const differenceInYears = differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

    const roundedDifference = Math.ceil(differenceInYears);

    return roundedDifference;
  };

  const isHighlightHit = (word: string) => {
    return applicant?.highlightSkills?.some((group: HighlightSkill[]) =>
      group.some((item: HighlightSkill) => item.value === word && item.type === 'hit'),
    );
  };

  const getInitials = (name: string) => {
    const nameParts = name.split(' ').filter(Boolean);  // Split the name and filter out empty strings
    const initials = nameParts.map(part => part[0].toUpperCase()).join('');  // Take the first letter of each part and make it uppercase
    return initials[0]+initials[1];
  };

  const handleOptionClick = async (option: string) => {
    try {
      await editJobApplicant(id, selectedCandidate.userId, { applicantStatus: option });
      toast.success(`${selectedCandidate.firstName} ${selectedCandidate.lastName} has been moved to ${option}`, { position: 'bottom-center' });
    } catch (error) {
      toast.error('Error Changing applicant status', { position: 'bottom-center' });
    }
    setIsOpen(false);
  };
  const options = ['Outstanding', 'Auto Assess', 'Shortlisted', 'Unsuccessful', 'Suitable', 'New'];

  return (
    <div className='flex w-full'>
      <div className='w-2/5 h-full bg-white shadow-md rounded-lg overflow-hidden my-8 flex justify-between'>
        <div className='h-[120vh] p-6 bg-gray-100'>
          <div className='items-center'>
            { selectedCandidate.symbol==='circle' ? (
              <div className='w-40 h-40 bg-gray-200 rounded-full ml-4 flex items-center justify-center text-3xl'>
                { getInitials(selectedCandidate.name) }
              </div>
            ) : (
              <img src={selectedCandidate.symbol.replace('image://', '')} alt='Profile Pic' className='w-40 h-40 rounded-full ml-4' />
            ) }
            <div>
              <h2 className='mt-6 mb-6 text-lg font-bold text-center'>{ selectedCandidate.firstName + ' ' + selectedCandidate.lastName }</h2>
              <p className='text-black-600 font-bold text-xs mt-3'>Phone number: { applicant?.contactInfo?.phone }</p>
              <p className='text-black-600 font-bold text-xs mt-3'>Email: { applicant?.email }</p>
              <div className='border-t border-black w-full my-4'>
                <p className='text-black-600 font-bold text-xs mt-3'>Status of the application: { applicant?.applicantStatus }</p>
                <p className='text-black-600 font-bold text-xs mt-3'>Date of application: 17/03/2024</p>
                <p className='text-black-600 font-bold text-xs mt-3'>Previous applications: 2</p>
              </div>
              <div className='mt-8 dropdown inline-block relative flex justify-center'>
                <button
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    onClick={handleButtonClick}
                >
                  <span>Move to</span>
                  <span className='ml-5'>&#9662;</span>
                </button>
                { isOpen && (
                  <div className='dropdown-content absolute bg-white border rounded mt-2 w-32 shadow-md z-10'>
                    { options.map((option, index) => (
                      <p
                          key={index}
                          className='py-2 px-4 cursor-pointer hover:bg-gray-200'
                          onClick={() => handleOptionClick(option)}
                      >
                        { option }
                      </p>
                    )) }
                  </div>
                ) }
              </div>
            </div>
          </div>
        </div>
        <div className='p-6 max-w-[300px]'>
          <h3 className='text-lg font-semibold mb-2'>Skills:</h3>
          <div className='flex flex-wrap mb-2 max-w-base max-h-[30vh] overflow-auto'>
            { applicant?.skills?.map((skill: string, index: number) => (
              <div key={index} className='flex flex-wrap'>
                { skill.split(' ').map((word: string, wordIndex: number) => (
                  <span
                      key={wordIndex}
                      className={`text-sm mr-1 mb-2 ${
                        isHighlightHit(word) ? 'font-bold text-green-600' : 'text-gray-800'
                      }`}
                  >
                    { word }
                  </span>
                )) }
              </div>
            )) }
          </div>
          <h3 className='text-lg font-semibold mb-2'>Work Experience:</h3>
          <div className='flex-col flex-wrap mb-2 max-w-base max-h-[30vh] overflow-auto'>
            { applicant?.workExperience?.map((exp, index) => (
              <div className ='flex-col mb-2' key={index}>
                <p className='text-sm font-medium text-gray-800'>Company: { exp.company } [{ formatDate(Number(exp.startDate)) }-{ formatDate(Number(exp.endDate)) }]</p>
                { exp.jobTitle.split(' ').map((word: string, wordIndex: number) => (
                  <span
                      key={wordIndex}
                      className={`text-sm mr-1 mb-2 ${
                        isHighlightHit(word) ? 'font-bold text-green-600' : 'text-gray-800'
                      }`}
                  >
                    { word }
                  </span>
                )) }
                <p className='text-sm'>Years worked: { getDifferenceInDays(exp.startDate, exp.endDate) }</p>
              </div>
            )) }
          </div>
          <h3 className='text-lg font-semibold mb-2'>Education:</h3>
          <div className='flex-col flex-wrap mt-2 mb-2 mr-2'>
            { applicant?.educations?.map((edu, index) => (
              <div
                  key={index}
                  className={'text-sm mr-1 mb-2 text-gray-800'}
              >
                <p>{ edu.university } - { edu.degree }</p>
                <p>Graduation Year: { formatDate(Number(edu.graduationYear)) }</p>
              </div>
            )) }
          </div>
        </div>
      </div>
      <div className='w-3/5 h-full flex justify-center items-center'>
        { loading ? (
          <p className='text-center'>Loading CV...</p>
        ) : (
          <PDFDisplay
              pdfUrl={applicant?.pdfUrl}
          />
        ) }
      </div>
    </div>
  );
};
