import classNames from 'classnames';
import React from 'react';

type BannerType = 'normal' | 'warning' | 'success' | 'danger';

type BannerProps = {
  title: string;
  message: string;
  type?: BannerType;
  className?: string;
};

const bannerStyles: Record<BannerType, string> = {
  normal: 'bg-gray-50 text-gray-800 border-l-gray-300',
  warning: 'bg-yellow-50 text-yellow-800 border-l-yellow-500',
  success: 'bg-green-50 text-green-800 border-l-green-500',
  danger: 'bg-red-50 text-red-800 border-l-red-500',
};

const TopBanner: React.FC<BannerProps> = ({ title, message, type = 'normal', className }) => {
  return (
    <div className={classNames('gap-3 p-2 px-4 border-l-4', bannerStyles[type], className)}>
      <div className='font-semibold'>{ title } - <span className='text-gray-400'>{ message }</span></div>
    </div>
  );
};

export default TopBanner;
