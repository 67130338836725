import classNames from 'classnames';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { SelectOptions } from '../../utils/types/react_select_types';
import inputStyles from '../input/input.module.css';
import { SecondaryTypography } from '../typography/typography';
import styles from './custom_select.module.css';
import { customStyles } from './custom_select_styles';

type AsyncCustomSelectProps = {
  name?: string
  label?: string,
  value?: SelectOptions | SelectOptions[]
  defaultOptions: SelectOptions[] | undefined,
  onChange: any,
  isMulti?: boolean,
  disabled?: boolean,
  loadOptions: (inputValue: string, callback: (options: any) => void) => void,
  onInputChange?: any,
  placeholder?: string
};

const AsyncCustomSelect = ({
  name = '',
  value,
  label,
  defaultOptions,
  loadOptions,
  onChange,
  onInputChange,
  disabled,
  isMulti,
  placeholder = 'Search ...',
}: AsyncCustomSelectProps) => {
  return (
    <div>
      {
        label &&
        <label
            className={styles.selectLabel}
        >
          <SecondaryTypography.XSmall
              className={classNames(inputStyles.labelStyles, styles.infoText)}
              fontWeight='semi-bold'
          >
            { label }
          </SecondaryTypography.XSmall>
        </label>
      }
      <AsyncSelect
          placeholder={placeholder}
          isClearable={true}
          name={name}
          cacheOptions
          value={value}
          loadingMessage={() => 'Loading...'}
          noOptionsMessage={() => 'No options'}
          loadOptions={loadOptions}
          onChange={onChange}
          styles={customStyles}
          defaultOptions={defaultOptions}
          onInputChange={onInputChange}
          maxMenuHeight={200}
          isDisabled={disabled}
          // @ts-ignore
          isMulti={isMulti}
      />
    </div>
  );
};

export default AsyncCustomSelect;
