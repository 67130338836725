import ReactECharts from 'echarts-for-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Applicant, TabData } from '../../../../utils/types/JobOpenings';

type SegmentModalProps = {
  segmentedCandidates: Applicant[],
  xCoords: number[],
  yCoords: number[],
  selectedCandidates: TabData[],
  setSelectedCandidates: (tabDataObject: TabData[]) => void | undefined,
  setIsModalOpen: (content: boolean) => void,
  setTabTitles: (titles: string[]) => void | undefined;
  setActiveTab: (value: number) => void,
  tabTitles: string[],
}

export const SegmentModal = ({
  segmentedCandidates,
  xCoords,
  yCoords,
  selectedCandidates,
  setSelectedCandidates,
  setTabTitles,
  setActiveTab,
  tabTitles,

}: SegmentModalProps) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [candidates, setCandidates] = useState<Applicant[]>(segmentedCandidates);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Backspace') {
      setSearchTerm((prev) => prev.slice(0, -1));
    } else if (/^[a-zA-Z\s]$/.test(event.key)) {
      const key = event.key.toLowerCase();
      setSearchTerm((prev) => prev + key);
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const filteredCandidates = segmentedCandidates.filter((candidate) =>
      candidate.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setCandidates(filteredCandidates);

  }, [searchTerm]);

  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = candidates.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(candidates.length / itemsPerPage);

  // Change page
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const openTab = (name : string, userId : string) => {
    const nameExists = selectedCandidates.some(item => item.candidate && item.candidate.userId === userId);
    if (!nameExists) {
      setTabTitles([...tabTitles, name]);
      const selectedItem:Applicant | undefined = candidates.find(item => item.userId === userId);
      const newSelectedCandidates = [...selectedCandidates, { tabId: tabTitles.length, candidate: selectedItem }];
      setSelectedCandidates(newSelectedCandidates);
      setActiveTab(tabTitles.length);
    } else {
      toast.error('Tab for ' + name + ' already open', { position: 'bottom-center' });
    }
  };

  const maxButtons = 5;
  const { startPage, endPage } = useMemo(() => {
    let startPage = currentPage - Math.floor(maxButtons / 2);
    let endPage = currentPage + Math.floor(maxButtons / 2);

    if (startPage <= 0) {
      startPage = 1;
      endPage = Math.min(maxButtons, totalPages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxButtons + 1);
    }

    return { startPage, endPage };
  }, [currentPage, totalPages, maxButtons]);

  const getOption = () => ({
    title: {
      text: 'Number of Candidates ' + candidates.length,
      left: 'center', // Align title to center
      textStyle: {
        fontSize: 14, // Font size of the title
      },
    },
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      name: 'Number of Skills',
      min: xCoords[0].toFixed(1),
      max: xCoords[1].toFixed(1),
      splitLine: {
        show: true,
      },
      nameLocation: 'middle',
      nameGap: 25,
    },
    yAxis: {
      name: 'Experience (Years)',
      min: yCoords[0].toFixed(1),
      max: yCoords[1].toFixed(1),
      splitLine: {
        show: true,
      },
      nameLocation: 'middle',
      nameGap: 30,
    },
    series: [{
      symbolSize: 25,
      data: candidates,
      type: 'scatter',
    }],
  });

  const onChartClick = (param:any) => {
    if (param.data && param.data.name) {
      if (param.componentType === 'series' && param.data) {
        const nameExists = selectedCandidates.some(item => item.candidate && item.candidate.userId === param.data.userId);
        if (!nameExists) {
          setTabTitles([...tabTitles, param.data.name]);
          const selectedItem:Applicant | undefined = candidates.find(item => item.userId === param.data.userId);
          const newSelectedCandidates = [...selectedCandidates, { tabId: tabTitles.length, candidate: selectedItem }];
          setSelectedCandidates(newSelectedCandidates);
          setActiveTab(tabTitles.length);
        } else {
          toast.error('Tab for ' + param.data.name + ' already open', { position: 'bottom-center' });
        }
      }
    }
  };

  return (
    <div className='flex'>
      <div className='fixed top-1/3 left-1/3 transform -translate-x-1/2 -translate-y-1/2 text-gray-400 text-base bg-white bg-opacity-80 p-2 rounded'>
        Searching for: { searchTerm }
      </div>
      <ReactECharts
          option={getOption()}
          style={{ height: '25vw', width: '72%' }}
          onEvents={{ click: onChartClick }}
      />
      <div>
        <ul>
          { currentItems.map(item => (
            <li key={item.userId} className='bg-white shadow-md rounded-lg p-2'>
              <div className='flex items-center'>
                { item.symbol === 'circle' ? (
                  <div className='w-10 h-10 bg-gray-200 rounded-full mr-2 flex items-center justify-center text-xl'>
                    { item.name.charAt(0) }
                  </div>
                ) : (
                  <img src={item.symbol.replace('image://', '')} alt='Profile Pic' className='w-10 h-10 rounded-full mr-2'/>
                ) }
                <div
                    onClick={() => openTab(item.firstName + ' ' + item.lastName, item.userId)}
                >
                  <h3 className='text-xs font-semibold'>{ item.firstName + ' ' + item.lastName }</h3>
                  <p className='text-xs text-gray-500'>{ 'Number of Skills ' + item.value[0] }</p>
                  <p className='text-xs text-gray-500'>{ 'Years of Experience ' + item.value[1] }</p>
                </div>
              </div>
            </li>
          )) }
        </ul>
        <div className='flex justify-center mt-4'>
          { [...Array(endPage - startPage + 1)].map((_, index) => (
            <button
                key={index}
                onClick={() => paginate(startPage + index)}
                className={`mx-1 px-3 py-1 rounded-full ${
                  currentPage === startPage + index ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                }`}
            >
              { startPage + index }
            </button>
          )) }
        </div>
      </div>
    </div>
  );
};
