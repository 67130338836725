import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DraggableQuestions } from '../../../../ui/draggable_questions/draggable_questions';
import { ArrowLeft } from '../../../../ui/icons/icons';
import { PrimaryTypography, SecondaryTypography } from '../../../../ui/typography/typography';
import { getAssessment, getAssessmentResultList } from '../../../../utils/api_service/assessment_api';
import { CandidateStatusEnum } from '../../../../utils/helpers/candidateStatus';
import { RootState } from '../../../../utils/redux_store/store';
import { Assessment, AssessmentUser, CandidateStatus, ResultRequestResponse, UserAssessmentResponse } from '../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import { Tabs } from '../tabs/tabs';
import commonStyles from '../view.module.css';
import { AssessmentEmptyList } from './assessment_empty_list/assessment_empty_list';
import { AssessmentResults } from './assessment_result_list/assessment_result_list';
import { AssessmentSummary } from './assessment_summary/assessment_summary';
import { AssessmentUsers } from './assessment_users/assessment_users';
import { OrgAssessmentUsers } from './OrgAssessmentUsers';
import { PartnerAssessmentUsers } from './PartnerAssessmentUsers';
import PreScreenQuestions from './PreScreenQuestions';
import { Settings } from './settings/settings';

const PRACTICE: string = 'practice';

export const ViewAssessment = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string, tab: string }>();
  const [assessmentInfo, setAssessmentInfo] = useState<Assessment>();
  const [activeTab, setActiveTab] = useState<number>(parseInt(tab) || 0);
  const [assessmentUserListResponse, setAssessmentUserListResponse] = useState<ResultRequestResponse>();
  const [highestResultScore, setHighestResultScore] = useState<number>();
  const skills = useSelector((state: RootState) => state.skills);
  const levels = useSelector((state: RootState) => state.skillLevels);
  const jobTitles = useSelector((state: RootState) => state.jobTitles);
  const subSkills = useSelector((state: RootState) => state.subSkills);
  const [skillList, setSkillList] = useState<SelectOptions[]>(skills.skillsList);
  const [loaded, setLoaded] = useState<boolean>(false);
  const practiceTab = ['List of Questions', 'Permissions and Settings', 'Results'];
  let tabTitles: string[] = [];

  if (assessmentInfo?.assessmentType === 'candidate') {
    tabTitles = [
      'Summary',
      'Candidate',
      'Pre Screen Questions',
      'List of Questions',
      'Permissions and Settings',
      CandidateStatusEnum.WAITLISTED,
      CandidateStatusEnum.SHORTLISTED,
      CandidateStatusEnum.UNSUCCESSFUL,
    ];
  } else if (assessmentInfo?.assessmentType === 'employee'){
    tabTitles = [
      'Summary',
      'Team Members',
      'List of Questions',
      'Permissions and Settings',
      'Results',
    ];
  } else {
    tabTitles = [
      'Summary',
      'Partners',
      'List of Questions',
      'Permissions and Settings',
      'Results',
    ];
  }

  useEffect(() => {
    getAssessment(id).then(response => {
      setAssessmentInfo(response);
    });
  }, [id, activeTab]);

  useEffect(() => {
    if (assessmentInfo?.id) {
      getAssessmentResultList(assessmentInfo.id).then(response => {
        if(!response.error && response.assessmentUserList.length !== 0) {
          setAssessmentUserListResponse(response);
          setHighestResultScore(Math.round(Math.max.apply(Math, response.assessmentUserList.map((result: UserAssessmentResponse) => result.score))/response.assessmentUserList[0].maxScore*100));
          setLoaded(true);
        }
      });
    }
  }, [assessmentInfo?.id]);

  useEffect(() => {
    setSkillList(skills.skillsList);
  }, [skills.skillsList]);

  const updateAssessmentUserList = (selectedRows: AssessmentUser[], candidateStatus: CandidateStatus) => {
    if (assessmentUserListResponse) {
      const updatedAssessmentUserListResponse = { ...assessmentUserListResponse };
      selectedRows.forEach((selectedRow) => {
        const userIndex = updatedAssessmentUserListResponse.assessmentUserList.findIndex(
          (user) => user.id === selectedRow.assessmentUserId,
        );
        if (userIndex !== -1) {
          updatedAssessmentUserListResponse.assessmentUserList[userIndex].candidateStatus = candidateStatus;
        }
      });
      setAssessmentUserListResponse(updatedAssessmentUserListResponse);
    }
  };

  const renderTabContent = () => {
    if (assessmentInfo) {
      if (activeTab === 0) {
        if (assessmentInfo.assessmentType !== PRACTICE){
          return (
            <AssessmentSummary
                assessment={assessmentInfo}
            />
          );
        }
        else {
          return (
            <div className='my-5'>
              <DraggableQuestions
                  assessmentId={assessmentInfo.id}
                  skillList={skillList}
                  levelList={levels.levelList}
                  jobTitleList={jobTitles.jobTitlesList}
                  subSkillList={subSkills.subSkillsList}
                  setSkillList={setSkillList}
              />
            </div>
          );
        }
      } else if (activeTab === 1) {
        if (assessmentInfo.assessmentType === 'employee' && assessmentInfo.queryFilter) {
          return (
            <OrgAssessmentUsers
                assessmentId={assessmentInfo.id}
                query={assessmentInfo.queryFilter}
            />
          );
        } else if (assessmentInfo.assessmentType === 'candidate') {
          return (
            <AssessmentUsers
                assessmentId={assessmentInfo.id}
            />
          );
        } else if (assessmentInfo.assessmentType === 'partner') {
          return (
            <PartnerAssessmentUsers
                assessmentId={assessmentInfo.id}
                query={assessmentInfo.queryFilter}
            />
          );
        }
        else {
          return <Settings assessmentType={assessmentInfo.assessmentType}/>;
        }
      } else if (activeTab === 2) {
        if (assessmentInfo.assessmentType === 'candidate'){
          return (
            <div className='my-5'>
              <PreScreenQuestions assessmentInfo={assessmentInfo} setAssessmentInfo={setAssessmentInfo}/>
            </div>
          );
        } else if (assessmentInfo.assessmentType === 'employee' || assessmentInfo.assessmentType === 'partner') {
          return (
            <div className='my-5'>
              <DraggableQuestions
                  assessmentId={assessmentInfo.id}
                  skillList={skillList}
                  levelList={levels.levelList}
                  jobTitleList={jobTitles.jobTitlesList}
                  subSkillList={subSkills.subSkillsList}
                  setSkillList={setSkillList}
              />
            </div>
          );
        } else {
          if (loaded) {
            return (
              <AssessmentResults
                  preScreenQuestions={assessmentInfo.preScreen ? assessmentInfo.preScreen : []}
                  assessmentId={assessmentInfo.id}
                  userResults={assessmentUserListResponse?.assessmentUserList || []}
                  completionRatio={assessmentUserListResponse?.completionRatio || ''}
                  highestScore={highestResultScore || 0}
              />
            );
          }
        }
      } else if (activeTab === 3) {
        if (assessmentInfo?.assessmentType === 'candidate') {
          return (
            <div className='my-5'>
              <DraggableQuestions
                  assessmentId={assessmentInfo.id}
                  skillList={skillList}
                  levelList={levels.levelList}
                  jobTitleList={jobTitles.jobTitlesList}
                  subSkillList={subSkills.subSkillsList}
                  setSkillList={setSkillList}
              />
            </div>
          );
        } else {
          return <Settings assessmentType={assessmentInfo.assessmentType}/>;
        }
      } else if (activeTab === 4) {
        if (assessmentInfo?.assessmentType === 'candidate') {
          return <Settings assessmentType={assessmentInfo.assessmentType}/>;
        } else {
          return (
            <AssessmentResults
                preScreenQuestions={assessmentInfo.preScreen ? assessmentInfo.preScreen : []}
                assessmentId={assessmentInfo.id}
                userResults={assessmentUserListResponse?.assessmentUserList || []}
                completionRatio={assessmentUserListResponse?.completionRatio || ''}
                highestScore={highestResultScore || 0}
            />
          );
        }
      } else if (activeTab === 5) {
        if (assessmentInfo?.assessmentType === 'candidate') {
          if (loaded) {
            return (
              <AssessmentResults
                  preScreenQuestions={assessmentInfo.preScreen ? assessmentInfo.preScreen : []}
                  assessmentId={assessmentInfo.id}
                  userResults={assessmentUserListResponse?.assessmentUserList || []}
                  completionRatio={assessmentUserListResponse?.completionRatio || ''}
                  highestScore={highestResultScore || 0}
                  candidateStatus={CandidateStatusEnum.WAITLISTED}
                  updateAssessmentUserList={updateAssessmentUserList}
              />
            );
          }
          else {
            return (
              <AssessmentEmptyList/>
            );
          }
        } else {
          return <AssessmentEmptyList />;
        }
      } else if (activeTab === 6) {
        if (loaded) {
          return (
            <AssessmentResults
                preScreenQuestions={assessmentInfo.preScreen ? assessmentInfo.preScreen : []}
                assessmentId={assessmentInfo.id}
                userResults={assessmentUserListResponse?.assessmentUserList || []}
                completionRatio={assessmentUserListResponse?.completionRatio || ''}
                highestScore={highestResultScore || 0}
                candidateStatus={CandidateStatusEnum.SHORTLISTED}
                updateAssessmentUserList={updateAssessmentUserList}
            />
          );
        }
        else{
          return (
            <AssessmentEmptyList/>
          );
        }
      } else if (activeTab === 7) {
        if (loaded) {
          return (
            <AssessmentResults
                preScreenQuestions={assessmentInfo.preScreen ? assessmentInfo.preScreen : []}
                assessmentId={assessmentInfo.id}
                userResults={assessmentUserListResponse?.assessmentUserList || []}
                completionRatio={assessmentUserListResponse?.completionRatio || ''}
                highestScore={highestResultScore || 0}
                candidateStatus={CandidateStatusEnum.UNSUCCESSFUL}
                updateAssessmentUserList={updateAssessmentUserList}
            />
          );
        }
        else{
          return (
            <AssessmentEmptyList/>
          );
        }
      }
    }
  };

  return (
    <div
        className={commonStyles.container}
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Assessments
      </PrimaryTypography.FiveXLarge>
      {
        assessmentInfo &&
          <>
            <div
                className={commonStyles.contentContainer}
            >
              <div
                  className='flex items-center cursor-pointer mb-2 gap-2 text-sky-500'
                  onClick={() => history.push('/assessment-list')}
              >
                <div>
                  <ArrowLeft className='h-4 w-4'/>
                </div>
                <SecondaryTypography.Small
                    fontWeight='bold'
                    keepDefaultMargins={false}
                    textCase='capitalize'
                >
                  Back to Assessment List
                </SecondaryTypography.Small>
              </div>
              <PrimaryTypography.FiveXLarge
                  fontWeight='bold'
                  keepDefaultMargins={true}
                  textCase='capitalize'
                  className='mb-2'
              >
                { assessmentInfo.assessmentTitle }
              </PrimaryTypography.FiveXLarge>
              <Tabs
                  tabTitles={assessmentInfo.assessmentType === PRACTICE ? practiceTab : tabTitles}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
              />
              { renderTabContent() }
            </div>
          </>
      }
    </div>
  );
};
