import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { getOrganization, updateOrganization } from '../../../../../utils/api_service/organization';
import { Organization } from '../../../../../utils/types/admin_types';
import LoadingSpinner from '../../../../job_openings/create/ui/LoaderSpinner';
import { UploadBase64Image } from './UploadBase64Image';

export const GeneralSettings = () => {
  const [organization, setOrganization] = useState<Organization>({
    organizationName: '',
    supportEmail: '',
    supportPhone: '',
    companyLogo: '',
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrganization = async () => {
      const response = await getOrganization();
      if (response.ok) {
        const organization = await response.json();
        setOrganization(organization);
        setLoading(false);
      }
    };

    fetchOrganization();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOrganization(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setCompanyLogo = (base64Url: string) => {
    setOrganization(prevState => ({
      ...prevState,
      companyLogo: base64Url,
    }));
  };

  const updateOrg = async () => {
    const response = await updateOrganization(organization);
    if (response.ok) {
      toast.success('Organization updated successfully', { position: 'bottom-center' });
    } else {
      response.json().then(errorData => {
        toast.error(errorData.error, { position: 'bottom-center' });
      });
    }
  };

  return (
    <>
      { loading?
        <div className='flex justify-center items-center min-h-[60vh]'>
          <LoadingSpinner label=''/>
        </div> :
        (
          <div className='space-y-4 py-5'>
            <div className='w-1/3'>
              <TextInput
                  name='organizationName'
                  label='Company Name'
                  value={organization?.organizationName || ''}
                  type='text'
                  onChange={handleChange}
              />
            </div>
            <div className='w-1/3'>
              <TextInput
                  name='supportEmail'
                  label='Company Email'
                  value={organization?.supportEmail || ''}
                  type='text'
                  onChange={handleChange}
              />
            </div>
            <div className='w-1/3'>
              <TextInput
                  name='supportPhone'
                  label='Support Phone'
                  value={organization?.supportPhone || ''}
                  type='text'
                  onChange={handleChange}
              />
            </div>
            <UploadBase64Image logo={organization.companyLogo || ''} setLogo={setCompanyLogo}/>
            <Button
                variant='primary'
                size='small'
                onClick={updateOrg}
            >
              Update Organization
            </Button>
          </div>
        )
      }
    </>
  );
};
