import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import RichTextEditor from '../../../../../../../ui/RichTextEditor';
import { updateAutoAssessmentEmail } from '../../../../../../../utils/api_service/JobOpenings';
import { getOrganization } from '../../../../../../../utils/api_service/organization';
import LoadingSpinner from '../../../../ui/LoaderSpinner';
import defaultEmailObjectTemplate from './email_template.json';

const defaultJsonTemplate = JSON.stringify(defaultEmailObjectTemplate);

type InvitationTemplateProps = {
  emailTemplate: string | undefined
  updateEmailTemplateState: (updatedJsonTemplate: string) => void,
}

export const InvitationTemplate = ({
  emailTemplate,
  updateEmailTemplateState,
}: InvitationTemplateProps) => {
  const { id } = useParams<{ id: string }>();
  const [editedTemplate, setEditedTemplate] = useState(emailTemplate || defaultJsonTemplate);
  const [loading, setLoading] = useState(true);
  const [variables, setVariables] = useState<{ name: string, variable: string}[]>([]);

  useEffect(() => {
    const fetchOrganization = async () => {
      const response = await getOrganization();
      if (response.ok) {
        const organization = await response.json();
        setVariables([
          { name: 'Applicant Name', variable: 'applicant_name' },
          { name: 'Company Name', variable: organization.organizationName },
          { name: 'Job Title Name', variable: 'job_title_name' },
          { name: 'End of Assessment', variable: 'end_of_assessment' },
          { name: 'Support Email', variable: organization.supportEmail },
          { name: 'Support Phone', variable: organization.supportPhone },
          { name: 'Company Logo', variable: organization.companyLogo },
          { name: 'Assessment Link', variable: 'assessment_link' },
          { name: 'Unset Link', variable: '' },
        ]);
        setLoading(false);
      }
    };

    fetchOrganization();
  }, []);

  const saveEmailTemplate = async (emailTemplate: string, emailJsonTemplate: string) => {
    try {
      const updateEmailResponse = await updateAutoAssessmentEmail({
        jobOpeningId: id,
        emailTemplate: emailTemplate,
        emailJsonTemplate: emailJsonTemplate,
      });
      if (updateEmailResponse.ok) {
        setEditedTemplate(emailJsonTemplate);
        updateEmailTemplateState(emailJsonTemplate);
        toast.success('Email Template Saved Successfully!', { position: 'bottom-center' });
      } else {
        throw new Error('Error updating email template');
      }
    } catch (error) {
      toast.error((error as Error).message, { position: 'bottom-center' });
    }
  };

  return (
    <>
      { loading ?
        <div className='flex justify-center items-center min-h-[60vh]'>
          <LoadingSpinner label=''/>
        </div> :
        (
          <div
              className='w-full p-5'
          >
            <RichTextEditor
                jsonStringTemplate={editedTemplate}
                validVariables={variables}
                saveTemplate={saveEmailTemplate}
            />
          </div>
        )
      }
    </>
  );
};
