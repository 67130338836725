import React from 'react';
import { Question, QuestionSearch, SubSkill } from '../../utils/types/assessment_types';
import { SelectOptions } from '../../utils/types/react_select_types';
import { Button } from '../button/button';
import { AddIcon } from '../icons/icons';
import { Filter } from './filter/filter';
import styles from './question_search_bar.module.css';
import { SearchBar } from './search_bar/search_bar';

type QuestionSearchProps = {
  onChange: (question: Question) => void,
  setFilterOptions: (event: any) => void,
  toggleSelected: (event: any) => void,
  searchObject: QuestionSearch,
  setSearchObject: (searchObject: QuestionSearch) => void,
  toggleAddQuestionModal: () => void,
  allCollapsibleState: boolean,
  toggleAllCollapsibleState: () => void,
  skillList: SelectOptions[] | undefined,
  levelList: SelectOptions[] | undefined,
  subSkillList: SubSkill[] | undefined,
  jobTitleList: SelectOptions[] | undefined,
}

export const QuestionSearchBar = ({
  onChange,
  searchObject,
  setSearchObject,
  toggleAddQuestionModal,
  allCollapsibleState,
  toggleAllCollapsibleState,
  skillList,
  levelList,
  subSkillList,
  jobTitleList,
}: QuestionSearchProps) => (
  <div
      className={styles.searchBarContainer}
  >
    <div
        style={{ width: '100%' }}
    >
      <Filter
          skillList={skillList || []}
          levelList={levelList || []}
          subSkillList={subSkillList}
          jobTitleList={jobTitleList}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
      />
      <SearchBar
          handleQuestionAdd={onChange}
          searchObject={searchObject}
      />
    </div>
    <Button
        onClick={toggleAllCollapsibleState}
        variant='secondary'
        size='medium'
    >
      { allCollapsibleState ? 'Collapse All' : 'Expand All' }
    </Button>
    <Button
        onClick={toggleAddQuestionModal}
        variant='success'
        size='medium'
        icon={<AddIcon/>}
    >
      New
    </Button>
    { /* <Button
        onClick={() => {}}
        variant='danger'
        size='medium'
        icon={<TrashIcon/>}
    >
      Delete
    </Button> */ }
  </div>
);
