import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useCallback } from 'react';
import ImageResize from 'tiptap-extension-resize-image';
import { Button } from '../button/button';
import EditorToolbar from './EditorToolbar';

interface RichTextEditorProps {
  jsonStringTemplate: string;
  validVariables?: { name: string, variable: string }[];
  saveTemplate: (template: string, jsonTemplate: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ jsonStringTemplate, validVariables, saveTemplate }) => {
  const VARIABLES = ['applicant_name', 'job_title_name', 'end_of_assessment'];
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: 'Write something...' }),
      Image.configure({
        allowBase64: true,
      }),
      ImageResize,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: 'https',
      }),
    ],
    editorProps: {
      attributes: {
        class: 'prose max-w-none [&_ol]:list-decimal [&_ul]:list-disc p-5 rounded-lg border-2 bg-gray-100',
      },
    },
    content: JSON.parse(jsonStringTemplate),
  });

  const handleInsertVariable = useCallback((variable: string) => {
    if (editor && variable.length !== 0) {
      const variableText = VARIABLES.includes(variable) ? `{{${variable}}}` : variable;
      editor.chain().focus().insertContent(variableText).run();
    }
  }, [editor]);

  const handleInsertImage = useCallback((variable: string) => {
    if (editor) {
      editor.chain().focus().setImage({ src: variable }).run();
    }
  }, [editor]);

  const setLink = useCallback((url: string) => {
    if (editor) {
      if (url === null) {
        return;
      }

      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      editor.chain().focus().extendMarkRange('link').setLink({ href: `{{${url}}}` }).run();
    }
  }, [editor]);

  const exportToHTML = () => {
    if (editor) {
      const html = editor.getHTML();
      const htmlJson =  JSON.stringify(editor.getJSON());
      if (jsonStringTemplate !== htmlJson) {
        saveTemplate(html, htmlJson);
      }
    }
  };

  const handleVariableClick = (variableName: string, variable: string) => {
    switch (variableName) {
      case 'Company Logo':
        return handleInsertImage(variable);
      case 'Assessment Link':
        return setLink(variable);
      case 'Unset Link':
        return editor && editor.chain().focus().unsetLink().run();
      default:
        return handleInsertVariable(variable);
    }
  };

  const variableDisabled = (variableName: string) => {
    if (editor) {
      switch (variableName) {
        case 'Assessment Link':
          return (editor.state.selection.to - editor.state.selection.from) === 0;
        case 'Unset Link':
          return !editor.isActive('link');
        default:
          return false;
      }
    } else {
      return;
    }
  };

  return (
    <>
      <EditorToolbar editor={editor} />
      <div className='flex flex-row'>
        <div className='w-full'>
          <EditorContent editor={editor}/>
        </div>
        { validVariables &&
        <div className='flex flex-col w-2/10'>
          { validVariables.map((variable) => (
            <Button
                key={variable.variable}
                variant='primary'
                onClick={() => handleVariableClick(variable.name, variable.variable)}
                size='medium'
                disabled={variableDisabled(variable.name)}
            >
              { variable.name }
            </Button>
          )) }
        </div>
        }
      </div>
      <Button
          variant='success'
          size='medium'
          onClick={exportToHTML}
      >
        Save
      </Button>
    </>
  );
};

export default RichTextEditor;
