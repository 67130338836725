import { createContext } from 'react';
import { jobOpeningDetailsTabs, jobOpeningTypes } from '../types/JobOpenings';

interface IJobOpeningsContext {
  jobOpeningElements: jobOpeningTypes[],
  setJobOpeningElements: (jobOpening: jobOpeningTypes[]) => void,
  completedJobOpeningDetails: jobOpeningDetailsTabs[],
  setCompletedJobOpeningDetails:(jobOpening: jobOpeningDetailsTabs[]) => void,
}

export const DefaultJobOpeningsState = {
  jobOpeningElements: [],
  completedJobOpeningDetails: [],
  setJobOpeningElements: () => {},
  setCompletedJobOpeningDetails: () => {},
};

export const JobOpeningsContext = createContext<IJobOpeningsContext>(DefaultJobOpeningsState);
