import { createRequest } from '../helpers/api_utils';
import { PreScreenQuestionBank, PreScreenQuestionType } from '../types/assessment_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/pre-screen';

export async function searchPreScreenQuestions(
  question: string,
  question_type: PreScreenQuestionType | null,
  page: number,
  page_size: number,
) {
  const queryParams = new URLSearchParams({
    question: question,
    page: page.toString(),
    page_size: page_size.toString(),
  });

  if (question_type) {
    queryParams.append('question_type', question_type);
  }

  const url = `${apiEndpoint}/search?${queryParams.toString()}`;
  return fetch(createRequest(url, 'GET', 'application/json', true));
}

export function createPreScreenQuestion(question: PreScreenQuestionBank) {
  const body = {
    question: question.question,
    questionType: question.questionType,
    choices: question.choices,
    defaultQuestion: question.defaultQuestion,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export function editPreScreenQuestion(question: PreScreenQuestionBank) {
  const body = {
    question: question.question,
    questionType: question.questionType,
    choices: question.choices,
    defaultQuestion: question.defaultQuestion,
  };
  return fetch(createRequest(`${apiEndpoint}/${question.id}`, 'PUT', 'application/json', true, body));
}

export function deletePreScreenQuestion(questionId: string) {
  return fetch(createRequest(`${apiEndpoint}/${questionId}`, 'DELETE', 'application/json', true));
}
