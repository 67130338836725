import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import {
  addQuestionToAssessment,
  deleteQuestionFromAssessment,
  getAssessmentQuestions,
  updateQuestionOrderInAssessment,
} from '../../utils/api_service/assessment_api';
import { addQuestion, updateQuestion } from '../../utils/api_service/job_api';
import { Permission } from '../../utils/helpers/permissions';
import { convertSecondsToTimeTaken, convertStringToUnix } from '../../utils/helpers/time';
import PermissionGate from '../../utils/hooks/PermissionGate';
import { editQuestionRedux } from '../../utils/redux_store/features/question_reducer';
import {
  Question,
  QuestionInfoTotalCount,
  QuestionSearch,
  QuestionSortOrder,
  SubSkill,
} from '../../utils/types/assessment_types';
import { SelectOptions } from '../../utils/types/react_select_types';
import { AddQuestionModal } from '../add_question_modal/add_question_modal';
import { CollapsibleQuestion } from '../collapsible_question/collapsible_question';
import { Modal } from '../modal/modal';
import { QuestionSearchBar } from '../question_search_bar/question_search_bar';
import { PrimaryTypography, SecondaryTypography } from '../typography/typography';
import styles from './draggable_questions.module.css';

type DraggableQuestionsProps = {
  assessmentId: string,
  skillList: SelectOptions[] | undefined,
  levelList: SelectOptions[] | undefined,
  jobTitleList: SelectOptions[] | undefined,
  subSkillList: SubSkill[] | undefined,
  setSkillList: any,
  completedElement?: () => void,
}

export const DraggableQuestions = ({
  assessmentId,
  skillList,
  levelList,
  jobTitleList,
  subSkillList,
  setSkillList,
  completedElement,
}: DraggableQuestionsProps) => {
  const dispatch = useDispatch();
  const [questionList, setQuestionList] = useState<Question[]>([]);
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState<boolean>(false);
  const [allCollapsibleOpen, setAllCollapsibleOpen] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<QuestionSearch>({
    questionTitle: '',
    skillId: '',
    levelId: '',
    subSkill1Id: '',
    subSkill2Id: '',
    subSkill3Id: '',
    jobTitle: '',
    assessmentId: assessmentId,
  });
  const [totalCount, setTotalCount] = useState<QuestionInfoTotalCount>({
    totalPoints: 0,
    totalTime: '00:00',
  });

  useEffect(() => {
    getAssessmentQuestions(assessmentId).then(response => {
      if (response.length > 0) {
        setQuestionList(response);
        let pointCounter: number = 0;
        let minuteCounter: number = 0;
        response.map((question: Question) => {
          pointCounter += question.points;
          minuteCounter += question.time;
        });
        let convertedTime = convertSecondsToTimeTaken(minuteCounter);
        setTotalCount({
          totalPoints: pointCounter,
          totalTime: convertedTime !== undefined ? convertedTime : '00:00',
        });
      }
    });
  }, [assessmentId]);

  const toggleAddQuestionModal = () => {
    setAddQuestionModalOpen(true);
  };

  const toggleAllCollapsible = () => {
    setAllCollapsibleOpen(!allCollapsibleOpen);
  };

  const handleChange = (question: Question) => {
    addQuestionToAssessment(questionList.length.toString(), question.id, assessmentId).then(response => {
      const newQuestion = Object.assign({ assessmentQuestionId: response.id }, question);
      setQuestionList(questionList => [
        ...questionList,
        newQuestion,
      ]);
      let convertedPrevious = convertStringToUnix(totalCount.totalTime) || 0;
      let timeUnixCalc = convertedPrevious + question.time;
      let convertedTime = convertSecondsToTimeTaken(timeUnixCalc);
      setTotalCount({
        totalPoints: totalCount.totalPoints + question.points,
        totalTime: convertedTime !== undefined ? convertedTime : '00:00',
      });
      if (completedElement) {
        completedElement();
      }
    });
  };

  const setFilterOptions = (event: any) => {
    setSearchObject({
      ...searchObject,
      [event.target.name]: event.target.value,
    });
  };

  const toggleSelected = (event: any) => {
    if (event.target.checked) {
      setSearchObject({
        ...searchObject,
        [event.target.name]: '',
      });
    }
  };

  const reOrderQuestionList = (list: Question[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const addNewQuestion = (question: Question) => {
    addQuestion(question).then(response => {
      handleChange(response);
      setAddQuestionModalOpen(false);
    });
  };

  const editQuestion = (question: Question) => {
    updateQuestion(question).then(response => {
      if (response.id) {
        let questionListCopy = questionList;
        let index = questionListCopy.findIndex(item => item.id === question.id);
        let editedQuestionPrevState = questionListCopy[index];
        questionListCopy[index] = question;
        setQuestionList(
          questionListCopy,
        );
        let convertedPrevious = convertStringToUnix(totalCount.totalTime) || 0;
        let timeUnixCalc = (convertedPrevious - editedQuestionPrevState.time) + question.time;
        let convertedTime = convertSecondsToTimeTaken(timeUnixCalc);
        let points: string = question.points.toString();
        setTotalCount({
          totalPoints: (totalCount.totalPoints - editedQuestionPrevState.points) + parseInt(points),
          totalTime: convertedTime !== undefined ? convertedTime : '00:00',
        });
        dispatch(editQuestionRedux(question));
      }
    });
  };

  const deleteQuestion = (id: string) => {
    deleteQuestionFromAssessment(id).then(response => {
      if (response.ok) {
        let questionListCopy = questionList;
        let index = questionListCopy.findIndex(item => item.assessmentQuestionId === id);
        let deletedQuestion = questionListCopy[index];
        questionListCopy.splice(index, 1);
        setQuestionList(
          questionListCopy,
        );
        let convertedPrevious = convertStringToUnix(totalCount.totalTime) || 0;
        let timeUnixCalc = convertedPrevious - deletedQuestion.time;
        let convertedTime = convertSecondsToTimeTaken(timeUnixCalc);
        setTotalCount({
          totalPoints: totalCount.totalPoints - deletedQuestion.points,
          totalTime: convertedTime !== undefined ? convertedTime : '00:00',
        });
      }
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const sorted = reOrderQuestionList(questionList, result.source.index, result.destination.index);
    let sortOrderArray: QuestionSortOrder[] = [];
    sorted.map((question: Question, index: number) => {
      if (question.assessmentQuestionId) {
        sortOrderArray.push({
          assessmentQnaId: question.assessmentQuestionId,
          sortOrder: index.toString(),
        });
      }
    });
    updateQuestionOrderInAssessment(sortOrderArray).then(response => {
      if (response.ok) {
        setQuestionList(
          sorted,
        );
      }
    });
  };

  const Questions = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='questions'>
        { (provided) => (
          <div
              className={styles.questionListContainer}
              id='questions'
              {...provided.droppableProps}
              ref={provided.innerRef}
          >
            { questionList.map((question: Question, index) => {
              return (
                <Draggable
                    key={question.id}
                    draggableId={question.id}
                    index={index}>
                  { (provided) => (
                    <CollapsibleQuestion
                        open={allCollapsibleOpen}
                        question={question}
                        provided={provided}
                        editQuestion={editQuestion}
                        deleteQuestion={deleteQuestion}
                        skillList={skillList}
                        levelList={levelList}
                        jobTitleList={jobTitleList}
                        subSkillList={subSkillList}
                        setSkillList={setSkillList}
                    />
                  ) }
                </Draggable>
              );
            }) }
          </div>
        ) }
      </Droppable>
    </DragDropContext>
  );

  return (
    <>
      <PermissionGate
          requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
      >
        <QuestionSearchBar
            onChange={handleChange}
            setFilterOptions={setFilterOptions}
            toggleSelected={toggleSelected}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            toggleAddQuestionModal={toggleAddQuestionModal}
            allCollapsibleState={allCollapsibleOpen}
            toggleAllCollapsibleState={toggleAllCollapsible}
            skillList={skillList}
            levelList={levelList}
            subSkillList={subSkillList}
            jobTitleList={jobTitleList}
        />
      </PermissionGate>
      <>
        <PrimaryTypography.TwoXLarge
            className={styles.questionHeader}
            fontWeight='bold'
        >
          List&nbsp;of&nbsp;questions
        </PrimaryTypography.TwoXLarge>
        { questionList.length > 0 ?
          <Questions/> :
          <div
              className='flex justify-center items-center h-screen max-h-[20vh]'
          >
            <SecondaryTypography.Small
                fontWeight='bold'
                keepDefaultMargins={true}
                textCase='uppercase'
            >
              There are currently no questions in this assessment. please add questions from the section above.
            </SecondaryTypography.Small>
          </div>
        }
        <div
            className={styles.questionListSummary}
        >
          <SecondaryTypography.Large
              fontWeight='semi-bold'
              alignment='left'
          >
            Total&nbsp;Score&nbsp;=&nbsp;{ totalCount.totalPoints }
          </SecondaryTypography.Large>
          <SecondaryTypography.Medium
              fontWeight='semi-bold'
              alignment='right'
          >
            Duration&nbsp;-&nbsp;{ }{ totalCount.totalTime }&nbsp;minutes
          </SecondaryTypography.Medium>
          <SecondaryTypography.Medium
              fontWeight='semi-bold'
              alignment='right'
          >
            { questionList.length }&nbsp;questions&nbsp;added
          </SecondaryTypography.Medium>
        </div>
      </>
      <Modal
          visible={addQuestionModalOpen}
          modalContent={
            <AddQuestionModal
                addNewQuestion={addNewQuestion}
                skillList={skillList || []}
                levelList={levelList || []}
                jobTitleList={jobTitleList || []}
                subSkillList={subSkillList}
                setSkillList={setSkillList}
            />
          }
          toggle={() => setAddQuestionModalOpen(false)}
      />
    </>
  );
};
