import { createRequest } from '../helpers/api_utils';
import { Organization } from '../types/admin_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/organization';

export async function getOrganization() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true));
}

export async function updateOrganization(organization: Organization) {
  return fetch(createRequest(apiEndpoint, 'PUT', 'application/json', true, organization));
}
