import * as echarts from 'echarts';
import React, { useEffect, useRef } from 'react';
import 'echarts-gl';
import { toast } from 'react-toastify';
import { Applicant, TabData } from '../../../../../utils/types/JobOpenings';

type Bar3DProps = {
    scatter: boolean,
    svgActiveTab: number,
    data: Applicant[],
    selectedCandidates: TabData[],
    setSelectedCandidates: (tabDataObject: TabData[]) => void | undefined,
    setTabTitles: (titles: string[]) => void | undefined;
    setActiveTab: (value: number) => void,
    tabTitles: string[],
}

export const Bar3D = ({
  scatter,
  svgActiveTab,
  data,
  setSelectedCandidates,
  selectedCandidates,
  setTabTitles,
  setActiveTab,
  tabTitles,
}: Bar3DProps) => {

  const chartRef = useRef<HTMLDivElement>(null);
  const newData = data.map(item => ({
    ...item,
    symbol: 'circle',
  }));

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);

      const option = {
        tooltip: {
          formatter: function (params: any) {
            const value = params.value; // The XYZ coordinates of the hovered bar
            const allData = option.series[0].data;

            // Filter data to find all items with the same XYZ coordinates
            const overlappingData = allData.filter(item =>
              item.value[0] === value[0] && item.value[1] === value[1] && item.value[2] === value[2],
            );

            const getInitials = (name: string) => {
              const nameParts = name.split(' ').filter(Boolean);  // Split the name and filter out empty strings
              if (nameParts.length > 1) {
                // If there's more than one name part, take the first letter of the first and last parts
                return (nameParts[0][0].toUpperCase() + nameParts[nameParts.length - 1][0].toUpperCase());
              } else {
                // If there's only one name part, just take the first letter of that part
                return nameParts[0][0].toUpperCase();
              }
            };

            let tooltipContent = '<div class=\'p-4\'>';
            tooltipContent += `
             <div class='flex-col'>
               <p class='mb-2 block'>Skills: ${value[0]}</p>
               <p class='mb-2 block'>Experience: ${value[1]}</p>
               <p class='mb-2 block'>Keywords: ${value[2]}</p>
             </div>`;
            overlappingData.forEach(item => {
              tooltipContent += `<div class='flex items-center mb-2'>
    ${ item.symbol === 'circle' ?
    `<div class='w-10 h-10 bg-gray-200 rounded-full mr-2 mt-1 flex items-center justify-center text-lg'>
                                         ${getInitials(item.name)}
                                       </div>`
    :
    `<img src=${item.symbol.replace('image://', '')} alt='Profile Pic' class='w-10 h-10 rounded-full mr-2' />`
}
                                        <strong>${item.name}</strong>
                                 </div>`;
            });

            tooltipContent += '</div>';

            return tooltipContent;
          },
        },
        visualMap: {
          max: 11,
          inRange: {
            color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026'],
          },
        },
        xAxis3D: {
          name: 'Number of Skills',
        },
        yAxis3D: {
          name: 'Years of Experience',
        },
        zAxis3D: {
          type: 'value',
          name: 'Keyword Counter',
        },
        grid3D: {
          boxWidth: 200,
          boxDepth: 80,
          viewControl: {
            // This can be adjusted for different control styles
            projection: 'orthographic',
          },
          light: {
            main: {
              intensity: 1.2,
              shadow: false,
            },
            ambient: {
              intensity: 0.3,
            },
          },
        },
        series: [{
          type: scatter? 'scatter3D' : 'bar3D',
          data: newData,
          barSize: 8,
          stack: 'stack',
        }],
      };
      myChart.setOption(option);

      myChart.on('click', function (param) {
        const nameExists = selectedCandidates.some(item => item.candidate && item.candidate.name === param.name);
        if(!nameExists){
          setTabTitles([...tabTitles, param.name]);
          if (typeof param.data === 'object' && param.data !== null && 'userId' in param.data) {
            const user = param.data.userId;
            const selectedItem:Applicant | undefined = data.find(item => item.userId === user);
            const newSelectedCandidates = [...selectedCandidates, { tabId: tabTitles.length, candidate: selectedItem }];
            setSelectedCandidates(newSelectedCandidates);
            setActiveTab(tabTitles.length);
          } else {
            toast.error('Tab for ' + param.name + ' already open', { position: 'bottom-center' });
          }
        }
        else{
          toast.error('Tab for ' + param.name + ' already open', { position: 'bottom-center' });
        }
      });
    }

    return () => {
      if (chartRef.current) {
        echarts.dispose(chartRef.current);
      }
    };
  }, [data, scatter]);

  return (
    <div
        ref={chartRef} style={{ height: '500px', width: svgActiveTab !== -1 ? '70%' : '100%' }}
    />
  );
};
