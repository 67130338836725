import classNames from 'classnames';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import styles from '../../../../ui/collapsible_question/collapsible_question.module.css';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { PencilIcon, TrashIcon } from '../../../../ui/icons/icons';
import { TextInput } from '../../../../ui/input/text_input/text_input';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { deletePreScreenQuestion, editPreScreenQuestion } from '../../../../utils/api_service/pre_screen_api';
import { PreScreenQuestionBank, PreScreenQuestionType } from '../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import { questionTypeOptions } from '../PreScreenSearch';

type PreScreenQuestionProps = {
  question: PreScreenQuestionBank
  deleteQuestion: (questionId: string) => void
  editQuestion: (editQuestion: PreScreenQuestionBank) => void
  validateQuestion: (
    questionText: string,
    questionType: PreScreenQuestionType,
    choices: string[],
    defaultQuestion: boolean,
  ) => PreScreenQuestionBank | null,
}

export const PreScreenQuestion = ({ question, deleteQuestion, editQuestion, validateQuestion }: PreScreenQuestionProps) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [editQuestionType, setEditQuestionType] = useState<PreScreenQuestionType>(question.questionType);
  const [editQuestionText, setEditQuestionText] = useState<string>(question.question);
  const [editChoices, setEditChoices] = useState<string[]>(question.choices && question.choices.length !== 0 ? question.choices: ['']);
  const [editDefaultQuestion, setEditDefaultQuestion] = useState<boolean>(question.defaultQuestion);

  const handleChoiceChange = (index: number, value: string) => {
    const newChoices = [...editChoices];
    newChoices[index] = value;
    setEditChoices(newChoices);
  };

  const handleDeleteQuestion = async () => {
    try {
      if (question.id) {
        const response = await deletePreScreenQuestion(question.id);
        if (response.ok) {
          deleteQuestion(question.id);
        } else {
          toast.error('Error deleting question', { position: 'bottom-center' });
        }
      }
    } catch (error) {
      toast.error('Error deleting question', { position: 'bottom-center' });
    }
  };

  const handleSaveQuestion = async () => {
    try {
      if (question.id) {
        let newQuestionData = validateQuestion(editQuestionText, editQuestionType, editChoices, editDefaultQuestion);

        if (!newQuestionData) return;
        newQuestionData = { ...newQuestionData, id: question.id };
        const response = await editPreScreenQuestion(newQuestionData);
        if (response.ok) {
          const result = await response.json();
          editQuestion(result);
          setEdit(false);
        } else {
          toast.error('Error saving question', { position: 'bottom-center' });
        }
      }
    } catch (error) {
      toast.error('Error saving question', { position: 'bottom-center' });
    }
  };

  const handleEditQuestion = () => {
    setEdit(true);
  };

  return (
    <div
        className={`flex flex-col gap-1 items-center mb-2 w-full bg-gray-100 p-5 rounded-lg border-2 
        ${edit ? 'border-green-400' : (question.defaultQuestion ? 'border-yellow-400' : '')}`}>
      { edit ? (
        <>
          <div className='flex gap-4 w-full'>
            <div className='w-8/12'>
              <TextInput
                  label='Question'
                  name='question'
                  value={editQuestionText}
                  type='text'
                  onChange={(e) => setEditQuestionText(e.target.value)}
              />
            </div>
            <div className='w-4/12'>
              <CustomSelect
                  label='Question Type'
                  name='questionType'
                  value={questionTypeOptions.find(option => option.value === editQuestionType)}
                  options={questionTypeOptions}
                  onChange={(selectedOption: SelectOptions) => setEditQuestionType(selectedOption.value as 'numeric' | 'multiple-choice')}
              />
            </div>
          </div>
          { editQuestionType === 'multiple-choice' && (
            <div className='mt-2 w-full'>
              <div className='text-[#6B7280]'>
                <SecondaryTypography.XSmall fontWeight='semi-bold'>
                  Options:
                </SecondaryTypography.XSmall>
              </div>
              { editChoices.map((choice, index) => (
                <div key={index} className='flex w-full'>
                  <div className='w-full mt-[6px]'>
                    <TextInput
                        name='choice'
                        value={choice}
                        type='text'
                        onChange={(e) => handleChoiceChange(index, e.target.value)}
                    />
                  </div>
                  <div>
                    { index === editChoices.length - 1 && (
                      <Button
                          onClick={() => setEditChoices([...editChoices, ''])}
                          variant='secondary'
                          size='small'
                      >
                        Add Choice
                      </Button>
                    ) }
                  </div>
                </div>
              )) }
            </div>
          ) }
          <div className='w-full flex justify-between items-center'>
            <div className='flex gap-2 items-center'>
              <input
                  type='checkbox'
                  className='w-5 h-5'
                  onChange={() => {setEditDefaultQuestion(!editDefaultQuestion);}}
                  checked={editDefaultQuestion}
              />
              <div className='text-xs'>
                Set As Default
              </div>
            </div>
            <div >
              <Button
                  onClick={() => handleSaveQuestion()}
                  variant='primary'
                  size='large'
              >
                Save
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='flex w-full gap-4'>
            <div className='w-8/12'>
              <TextInput
                  label='Question'
                  name={`question-${question.id}`}
                  value={question.question}
                  type='text'
                  disabled={true}
                  onChange={() => {
                  }}
              />
            </div>
            <div className='w-3/12'>
              <TextInput
                  label='Question Type'
                  name={`question-type-${question.id}`}
                  value={questionTypeOptions.find(option => option.value === question.questionType)?.label || ''}
                  type='text'
                  disabled={true}
                  onChange={() => {
                  }}
              />
            </div>
            <div
                className={`${classNames(styles.icons, styles.editIcon, styles.clickable)} self-center py-1`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditQuestion();
                }}
            >
              <PencilIcon className='h-8 w-8'/>
            </div>
            <div
                className={`${classNames(styles.icons, styles.trashIcon, styles.clickable)} self-center py-1`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteQuestion();
                }}
            >
              <TrashIcon className='h-8 w-8'/>
            </div>
          </div>
          { question.questionType === 'multiple-choice' && (
            <div className='w-full flex flex-col gap-1'>
              <div className='text-[#6B7280] mt-[6px]'>
                <SecondaryTypography.XSmall fontWeight='semi-bold'>
                  Options:
                </SecondaryTypography.XSmall>
              </div>
              { question.choices?.map((choice, idx) => (
                <div key={idx} className='w-full'>
                  <TextInput
                      name={`choice-${question.id}-${idx}`}
                      value={choice}
                      type='text'
                      disabled={true}
                      onChange={() => {
                      }}
                  />
                </div>
              )) }
            </div>
          ) }

        </>
      ) }
    </div>
  );
};
