import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import AsyncCustomSelect from '../../../../../ui/custom_react_select/async_select';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { DatePicker } from '../../../../../ui/input/date_picker/date_picker';
import TopBanner from '../../../../../ui/TopBanner';
import { createJobRequisition } from '../../../../../utils/api_service/JobOpenings';
import useDebouncedEmployeeSearch from '../../../../../utils/helpers/AsyncSelect/EmployeeGet';
import { EmployeeUser } from '../../../../../utils/types/admin_types';
import { JobRequisition, applicantScopeType } from '../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../utils/types/react_select_types';

const  jobRequisitionTypes: SelectOptions[] = [{
  label: 'New Role',
  value: 'New Role' ,
}, {
  label: 'Replacement',
  value: 'Replacement',
}];

const applicantTypes: SelectOptions[] = [{
  label: 'External',
  value: 'External',
}, {
  label: 'Internal',
  value: 'Internal',
}];

type JobRequisitionTabProps = {
  jobRequisition: JobRequisition,
  setJobRequisition: React.Dispatch<React.SetStateAction<JobRequisition>>,
  employees: EmployeeUser[],
  defaultHiringManager: SelectOptions[] | undefined,
  defaultInternalRecruiter: SelectOptions[] | undefined,
  setDefaultHiringManager: React.Dispatch<React.SetStateAction<SelectOptions[] | undefined>>,
  setDefaultInternalRecruiter: React.Dispatch<React.SetStateAction<SelectOptions[] | undefined>>,
  completedElement: () => void,
  editable: boolean,
}

const JobRequisitionTab = ({
  jobRequisition,
  setJobRequisition,
  employees,
  defaultHiringManager,
  defaultInternalRecruiter,
  setDefaultInternalRecruiter,
  setDefaultHiringManager,
  completedElement,
  editable } : JobRequisitionTabProps) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState({
    startDate: jobRequisition.deadline as string,
    endDate: jobRequisition.deadline as string,
  });
  const { loadOptionsDebounced } = useDebouncedEmployeeSearch();

  const handleChange = (selectedOptions: SelectOptions[] | SelectOptions, { name }: { name: string }) => {
    if (name === 'applicantScope') {
      const optionsArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
      const updatedValues = optionsArray.map(item => item.value) as applicantScopeType[];
      setJobRequisition(prevState => ({
        ...prevState,
        applicantScope: updatedValues,
      }));
    } else if (name === 'jobRequisitionType') {
      const selectedValue = !Array.isArray(selectedOptions) ? selectedOptions.value : (selectedOptions.length > 0 ? selectedOptions[0].value : null);
      if (selectedValue === 'New Role' || selectedValue === 'Replacement') {
        setJobRequisition(prevState => ({
          ...prevState,
          jobRequisitionType: selectedValue,
        }));
      }
    }
  };

  const defaultEmployeeUserOptions = employees.map((employee) => ({
    value: employee.id,
    label: employee.user.firstName + ' ' + employee.user.lastName,
  }));

  const handleEmployeeChange = (selectedOption: SelectOptions[],  { name }: { name: string }) => {
    if (name === 'internalRecruiter') {
      setDefaultInternalRecruiter(selectedOption);
    } else if (name === 'hiringManager') {
      setDefaultHiringManager(selectedOption);
    } else {
      return;
    }
    const stringList = selectedOption.map(item => item.value);
    setJobRequisition({
      ...jobRequisition,
      [name]: stringList,
    });
  };

  const changeDate = (timeRange: any) => {
    setJobRequisition({
      ...jobRequisition,
      deadline: timeRange.startDate as string,
    });
    setDate(timeRange);
  };

  const submitJobRequisition = async () => {
    setLoading(true);
    try {
      const response = await createJobRequisition(jobRequisition);
      if (response.ok) {
        const data : JobRequisition = await response.json();
        setJobRequisition({
          ...jobRequisition,
          id: data.id,
        });
        toast.success('Job Requisition Saved Successfully', { position: 'bottom-center' });
        completedElement();
      } else {
        toast.error('Error Submitting Job Requisition', { position: 'bottom-center' });
      }
    } catch {
      toast.error('Error Submitting Job Requisition', { position: 'bottom-center' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='relative flex flex-col gap-4 h-full'>
      { !editable && (
        <TopBanner
            title='This page is not editable'
            message='CV has been uploaded therefore these values are now not editable.'
            type='normal'/>
      ) }
      <div className='w-2/3'>
        <div className='text-gray-800 py-1 text-sm'>
          Job Requisition Type
        </div>
        <CustomSelect
            name='jobRequisitionType'
            value={{ label: jobRequisition.jobRequisitionType, value: jobRequisition.jobRequisitionType }}
            options={jobRequisitionTypes}
            onChange={handleChange}
            disabled={!editable}
        />
      </div>
      <div className='w-2/3'>
        <div className='text-gray-800 py-1 text-sm'>
          Applicant Scope
        </div>
        <CustomSelect
            name='applicantScope'
            value={jobRequisition.applicantScope.map(item => {
              return { label: item, value: item };
            })}
            options={applicantTypes}
            onChange={handleChange}
            isMulti={true}
            disabled={!editable}
        />
      </div>
      <div className='w-2/3'>
        <div className='text-gray-800 py-1 text-sm'>
          Hiring Manager Name(s)
        </div>
        <AsyncCustomSelect
            name='hiringManager'
            value={defaultHiringManager}
            defaultOptions={defaultEmployeeUserOptions}
            onChange={handleEmployeeChange}
            loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
            isMulti={true}
            disabled={!editable}
        />
      </div>
      <div className='w-2/3'>
        <div className='text-gray-800 py-1 text-sm'>
          Internal Recruiter Name(s)
        </div>
        <AsyncCustomSelect
            name='internalRecruiter'
            value={defaultInternalRecruiter}
            defaultOptions={defaultEmployeeUserOptions}
            onChange={handleEmployeeChange}
            loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
            isMulti={true}
            disabled={!editable}
        />
      </div>
      <div className='w-2/3'>
        <div className='text-gray-800 py-1 text-sm'>
          Deadline
        </div>
        <DatePicker
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={changeDate}
            asSingle={true}
            useRange={false}
            disabled={!editable}
        />
      </div>
      <div className='absolute bottom-0 right-0'>
        <Button
            onClick={() => submitJobRequisition()}
            variant='primary'
            size='medium'
            disabled={loading || jobRequisition.applicantScope.length === 0 || (jobRequisition.deadline === '' || jobRequisition.deadline === 0) || jobRequisition.hiringManager.length === 0 || jobRequisition.internalRecruiter.length === 0 || !editable}
        >
          Save Job Requisition
        </Button>
      </div>
    </div>
  );
};

export default JobRequisitionTab;
