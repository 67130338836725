import React, { ReactNode } from 'react';

type IconButtonProps = {
    onClick: () => void;
    icon: ReactNode;
}

export const IconButton = ({
  onClick,
  icon,
}: IconButtonProps) => {
  return (
    <div
        className='w-10 h-10 bg-white m-2 p-2 rounded hover:bg-primary-green-300 hover:cursor-pointer'
        onClick={onClick}
    >
      { icon }
    </div>
  );
};
