import classNames from 'classnames';
import React from 'react';
import { Button } from '../../../../../ui/button/button';
import { ClockIcon, OpenLockIcon } from '../../../../../ui/icons/icons';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import { convertSecondsToTimeTaken } from '../../../../../utils/helpers/time';
import { AssessmentQuestionInfo, UserAssessment } from '../../../../../utils/types/assessment_types';
import commonStyles from '../assigned_assessments.module.css';
import styles from './question.module.css';
import { QuestionArea } from './question_area/question_area';

type QuestionProps = {
    selectedAssessment: UserAssessment | undefined,
    videoElementRef: React.RefObject<HTMLVideoElement>,
    currentQuestion: AssessmentQuestionInfo | undefined,
    currentAnswer: string | File | undefined,
    setCurrentAnswer: (value: string | File | undefined) => void,
    nextQuestion: (skip: boolean | null | undefined | void) => void,
    recordedVideo: string | undefined,
    setRecordedVideo: (value: string | undefined) => void,
    timeRemaining: number | undefined,
    isPractice: boolean | undefined,
}

export const Question = ({
  selectedAssessment,
  nextQuestion,
  currentQuestion,
  currentAnswer,
  setCurrentAnswer,
  recordedVideo,
  setRecordedVideo,
  timeRemaining,
  isPractice,
}: QuestionProps) => (
  <div
      className={commonStyles.contentContainer}
  >
    <div
        className={styles.assessmentHeader}
    >
      <PrimaryTypography.TwoXLarge
          fontWeight='bold'
      >
        { isPractice ? 'Practice Assessment' : selectedAssessment && selectedAssessment.assessment.assessmentTitle }
      </PrimaryTypography.TwoXLarge>
    </div>
    <div
        className='flex flex-col sm:flex-row'
    >
      { currentQuestion &&
        <div
            className='w-full p-20 sm:w-100 p-3'
        >
          <PrimaryTypography.SevenXLarge
              fontWeight='bold'
              keepDefaultMargins={true}
          >
            { currentQuestion.question }
          </PrimaryTypography.SevenXLarge>
          <QuestionArea
              questionType={currentQuestion.questionType || 'Classic'}
              setCurrentAnswer={setCurrentAnswer}
              recordedVideo={recordedVideo}
              setRecordedVideo={setRecordedVideo}
              currentQuestion={currentQuestion}
              timeRemaining={timeRemaining}
          />
        </div>
      }
      <div
          className='box-border w-15 p-15 sm:w-100 p-5'
      >
        <SecondaryTypography.Small
            className={commonStyles.greyText}
        >
          During the process of the assessment your camera movements and browser actions will be monitored.
        </SecondaryTypography.Small>
        <p className='text-sm text-red-600 font-semibold pb-2'>Leaving fullscreen mode will be monitored and reported as suspicious activity to the reviewer.</p>
        <div
            className={classNames(commonStyles.iconInfoContainer, commonStyles.darkBlueText)}
        >
          <div
              className={commonStyles.iconClass}
          >
            <OpenLockIcon/>
          </div>
          <SecondaryTypography.Medium
              fontWeight='semi-bold'
              keepDefaultMargins={true}
          >
            Total: { selectedAssessment && selectedAssessment.assessment.totalScore } points
          </SecondaryTypography.Medium>
        </div>
        <div
            className={classNames(commonStyles.iconInfoContainer, commonStyles.redText)}
        >
          <div
              className={commonStyles.iconClass}
          >
            <ClockIcon/>
          </div>
          <SecondaryTypography.Medium
              fontWeight='bold'
          >
            Time left: { timeRemaining ? convertSecondsToTimeTaken(timeRemaining) : '00:00' }
          </SecondaryTypography.Medium>
        </div>
      </div>
    </div>
    <div
        className={styles.bottomButtonContainer}
    >
      <Button
          variant='secondary'
          size='medium'
          onClick={() => nextQuestion(true)}
          disabled={false}
      >
        Skip
      </Button>
      <Button
          variant='primary'
          size='medium'
          onClick={() => nextQuestion()}
          disabled={currentAnswer === undefined}
      >
        Next Question
      </Button>
    </div>
  </div>
);

