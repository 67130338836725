import React from 'react';

const CompanyDetails = () => {

  return (
    <>
      { /*<TextArea*/ }
      { /*    name={'x'}*/ }
      { /*    value={''}*/ }
      { /*    type={'string'}*/ }
      { /*    onChange={onSearchTermChange}*/ }
      { /*/>*/ }
    </>
  );
};

export default CompanyDetails;
