import React, { useEffect, useMemo, useState } from 'react';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import AsyncCustomSelect from '../../../../../ui/custom_react_select/async_select';
import { CheckboxMultiSelect } from '../../../../../ui/custom_react_select/CheckboxMultiSelect';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { getFormattedOrgList } from '../../../../../ui/data_source_filter/CollapsibleFilter';
import { MultiSelectListItem } from '../../../../../ui/data_source_filter/MultiSelectListItem';
import { Loader } from '../../../../../ui/loader/loader';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { getAssessmentUsers, setAssessmentEmployeeQuery } from '../../../../../utils/api_service/assessment_api';
import { getEmployeeList } from '../../../../../utils/api_service/employees';
import { getJobTitleList } from '../../../../../utils/api_service/job_title';
import { getOrganizationTree } from '../../../../../utils/api_service/organization_unit';
import { searchOrganizationUsers } from '../../../../../utils/api_service/organization_users';
import { debounce } from '../../../../../utils/helpers/debounce';
import { EmployeeUser, JobTitle, OrganizationUser } from '../../../../../utils/types/admin_types';
import { FormattedSelectOptions } from '../../../../../utils/types/analytics/analytics_types';
import { AssessmentUser, QueryFilter, UserAssessmentResponse } from '../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import { OrganizationUnit } from '../../../../admin/OrganizationSettings/TreeStructure';
import commonStyles from '../../assessment_list/assessment_list_table/assessment_list_table.module.css';
import { AssessmentUserRow } from '../assessment_users/assessment_user_row/assessment_user_row';
import styles from './OrgAssessmentUsers.module.css';

type OrgAssessmentUsersProps = {
  assessmentId: string,
  query: QueryFilter,
}

export const OrgAssessmentUsers = ({
  assessmentId,
  query,
}: OrgAssessmentUsersProps) => {
  const [organizationTree, setOrganizationTree] = useState<OrganizationUnit>();
  const [employeeList, setEmployeeList] = useState<EmployeeUser[]>([]);
  const [jobTitleList, setJobTitleList] = useState<JobTitle[]>([]);
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<FormattedSelectOptions[]>([]);
  const [selectedRepManagers, setSelectedRepManagers] = useState<SelectOptions[]>([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState<SelectOptions[]>([]);
  const [includedUsers, setIncludedUsers] = useState<SelectOptions[]>([]);
  const [excludedUsers, setExcludedUsers] = useState<SelectOptions[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<AssessmentUser[]>([]);
  const [isLoadingOrgUnits, setIsLoadingOrgUnits] = useState(true);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(true);
  const [isLoadingJobTitles, setIsLoadingJobTitles] = useState(true);
  const [queryObject, setQueryObject] = useState<QueryFilter>({
    excludedUser: query.excludedUser,
    includedUser: query.includedUser.map((user: any) => user.id),
    organizationUnitId: query.organizationUnitId,
    reportingManager: query.reportingManager.map((rep: any) => rep.id),
    jobTitleId: query.jobTitleId.map((job: any) => job.id),
  });

  useEffect(() => {
    getOrganizationTree().then(response => {
      setOrganizationTree(response);
      setIsLoadingOrgUnits(false);
    });

    const fetchEmployees = async () => {
      try {
        const response = await getEmployeeList();
        if (response.ok) {
          const data = await response.json();
          if (data && data.employee && data.employee.length > 0) {
            setEmployeeList(data.employee);
            const repManagerIds = queryObject.reportingManager;
            const repManagerOptions = repManagerIds.map(id => {
              const employee: EmployeeUser = data.employee.find((emp: EmployeeUser) => emp.id === id);
              return { value: id, label: employee ? employee.user.firstName + ' ' + employee.user.lastName : '' };
            });

            const includedUserIds = queryObject.includedUser;
            const includedUserOptions = includedUserIds.map(id => {
              const employee: EmployeeUser = data.employee.find((emp: EmployeeUser) => emp.id === id);
              return { value: id, label: employee ? employee.user.firstName + ' ' + employee.user.lastName : '' };
            });

            const excludedUserIds = queryObject.excludedUser;
            const excludedUserOptions = excludedUserIds.map(id => {
              const employee: EmployeeUser = data.employee.find((emp: EmployeeUser) => emp.id === id);
              return { value: id, label: employee ? employee.user.firstName + ' ' + employee.user.lastName : '' };
            });

            setSelectedRepManagers(repManagerOptions);
            setIncludedUsers(includedUserOptions);
            setExcludedUsers(excludedUserOptions);
          }
          setIsLoadingEmployees(false);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
      }
    };
    fetchEmployees();

    getJobTitleList().then(response => {
      if (response.length > 0) {
        setJobTitleList(response);

        const jobTitleIds = query.jobTitleId;

        const jobTitleOptions = jobTitleIds.map((item: any) => ({
          value: item.id,
          label: item.jobTitle,
        }));

        setSelectedJobTitles(jobTitleOptions);
      }
      setIsLoadingJobTitles(false);
    });

    getAssessmentUsers(assessmentId).then(response => {
      if (response.length > 0) {
        let assigned: AssessmentUser[] = [];
        response.map((item: UserAssessmentResponse) => {
          let assessmentUser: AssessmentUser = {
            assessmentUserId: item.id,
            userId: item.userId,
            firstName: item.user.firstName,
            lastName: item.user.lastName,
            email: item.user.email,
            score: item.score,
            status: item.status,
            enabled: item.enabled,
          };
          assigned = [...assigned, assessmentUser];
        });
        setAssignedUsers(assigned);
      }
    });
  }, []);

  const formattedOrgList = useMemo(() => getFormattedOrgList(0, organizationTree), [organizationTree]);

  useEffect(() => {
    const selectedList = formattedOrgList.filter((option => queryObject.organizationUnitId.includes(option.value)));
    setSelectedOrgUnits(selectedList);
  }, [formattedOrgList, queryObject.organizationUnitId]);

  const employeeOptions = useMemo(() =>
    employeeList.map((employee: EmployeeUser) => ({
      value: employee.id,
      label: employee.user.firstName + ' ' + employee.user.lastName,
    })),
  [employeeList],
  );

  const excludeOptions = useMemo(() => {
    const mappedOptions = assignedUsers.map((employee: AssessmentUser) => ({
      value: employee.userId,
      label: employee.firstName + ' ' + employee.lastName,
    }));
    const removeIncludeUsers = includedUsers.map((employee: SelectOptions) => employee.value);
    const finalOptions = mappedOptions.filter((option) => !removeIncludeUsers.includes(option.value));

    return finalOptions;
  }, [assignedUsers, includedUsers]);

  const jobTitleOptions = useMemo(() =>
    jobTitleList.map((title: JobTitle) => ({
      value: title.id,
      label: title.jobTitle,
    })),
  [jobTitleList],
  );

  const getQueryResults = async (query: QueryFilter) => {
    try {
      const response = await searchOrganizationUsers(query);
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          let assigned: AssessmentUser[] = [];
          data.map((orgUser: OrganizationUser) => {
            let assessmentUser: AssessmentUser = {
              assessmentUserId: '',
              userId: orgUser.id,
              firstName: orgUser.user.firstName,
              lastName: orgUser.user.lastName,
              email: orgUser.user.email,
              score: 0,
              status: 'Assigned',
              enabled: true,
            };
            assigned = [...assigned, assessmentUser];
          });
          setAssignedUsers(assigned);
        } else {
          setAssignedUsers([]);
        }
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error getting query data: ' + error, { position: 'bottom-center' });
    }
  };

  const getValuesFromSelectOptions = (options: SelectOptions[]): string[] => {
    return options.map(option => option.value);
  };

  const saveQuery = () => {
    let query: QueryFilter = {
      ...queryObject,
      assessmentId: assessmentId,
    };

    setAssessmentEmployeeQuery(query).then(response => {
      if (response.id) {
        getAssessmentUsers(response.id).then(response => {
          if (response.length > 0) {
            let assigned: AssessmentUser[] = [];
            response.map((item: UserAssessmentResponse) => {
              let assessmentUser: AssessmentUser = {
                assessmentUserId: item.id,
                userId: item.userId,
                firstName: item.user.firstName,
                lastName: item.user.lastName,
                email: item.user.email,
                score: item.score,
                status: item.status,
                enabled: item.enabled,
              };
              assigned = [...assigned, assessmentUser];
            });
            setAssignedUsers(assigned);
          } else {
            setAssignedUsers([]);
          }
        });
      }
    });
  };

  const handleJobTitleChange = (selectedOptions: SelectOptions[]) => {
    setSelectedJobTitles(selectedOptions);
    const newQueryObject: QueryFilter = {
      ...queryObject,
      jobTitleId: getValuesFromSelectOptions(selectedOptions),
    };
    setQueryObject(newQueryObject);
    getQueryResults(newQueryObject);
  };

  const handleReportingManagerChange = (selectedOptions: SelectOptions[]) => {
    setSelectedRepManagers(selectedOptions);
    const newQueryObject: QueryFilter = {
      ...queryObject,
      reportingManager: getValuesFromSelectOptions(selectedOptions),
    };
    setQueryObject(newQueryObject);
    getQueryResults(newQueryObject);
  };

  const addNestedOptions = (selectedValues: MultiValue<FormattedSelectOptions>) => {
    const selectCandidates = new Set<FormattedSelectOptions>();

    for (const selectedValue of selectedValues) {
      const currentLevel = (selectedValue as FormattedSelectOptions).level;
      selectCandidates.add(selectedValue);

      const index = formattedOrgList.findIndex(e => e.value === selectedValue.value);
      for (let i = index + 1; i < formattedOrgList.length; i++) {
        const element = formattedOrgList[i];
        if (element.level > currentLevel) {
          selectCandidates.add(element);
        } else {
          break;
        }
      }
    }

    return Array.from(selectCandidates);
  };

  const removeNestedOptions = (
    selectedValue: FormattedSelectOptions,
    formattedOrgList: FormattedSelectOptions[],
  ) => {
    let selectedOrgs = selectedOrgUnits;
    selectedOrgs = selectedOrgs.filter(unit => unit.value !== selectedValue.value);
    const index = formattedOrgList.findIndex((e) => e.value === selectedValue.value);
    if (index !== -1) {
      for (let i = index + 1; i < formattedOrgList.length; i++) {
        const element = formattedOrgList[i];
        if (element.level > selectedValue.level) {
          selectedOrgs = selectedOrgs.filter(unit => unit.value !== element.value);
        } else {
          break;
        }
      }
    }

    return selectedOrgs;
  };

  const managerLoadOptions = async (query: string) => {
    try {
      const response = await getEmployeeList(query, 1, 10);
      if (response.ok) {
        const data = await response.json();

        return data.employee.map((employee: EmployeeUser) => ({
          value: employee.id,
          label: `${employee.user.firstName} ${employee.user.lastName}`,
        }));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
      return [];
    }
  };

  const loadOptionsDebounced =  debounce((inputValue: string, callback: (options: any) => void) => {
    managerLoadOptions(inputValue).then(options => callback(options));
  }, 500);

  const handleOrgUnitSelect = (selectedValues: MultiValue<FormattedSelectOptions>, action: any) => {
    let selectedUnits;
    if (action.action === 'remove-value' || action.action === 'deselect-option') {
      selectedUnits = removeNestedOptions(action.removedValue || action.option, formattedOrgList);
    } else {
      selectedUnits = addNestedOptions(selectedValues);
    }

    setSelectedOrgUnits(selectedUnits);
    const newQueryObject: QueryFilter = {
      ...queryObject,
      organizationUnitId: selectedValues ? getValuesFromSelectOptions(selectedUnits) : [],
    };
    setQueryObject(newQueryObject);
    getQueryResults(newQueryObject);
  };

  const handleIncludedUserChange = (selectedOptions: SelectOptions[]) => {
    setIncludedUsers(selectedOptions);
    const newQueryObject: QueryFilter = {
      ...queryObject,
      includedUser: getValuesFromSelectOptions(selectedOptions),
    };
    setQueryObject(newQueryObject);
    getQueryResults(newQueryObject);
  };

  const handleExcludedUserChange = (selectedOptions: SelectOptions[]) => {
    setExcludedUsers(selectedOptions);
    const newQueryObject: QueryFilter = {
      ...queryObject,
      excludedUser: getValuesFromSelectOptions(selectedOptions),
    };
    setQueryObject(newQueryObject);
    getQueryResults(newQueryObject);

  };

  return (
    <>
      { isLoadingJobTitles || isLoadingEmployees || isLoadingOrgUnits ? (
        <Loader
            loading={isLoadingJobTitles || isLoadingEmployees || isLoadingOrgUnits}
        />
      ) : (
        <>
          <div
              className={styles.inputContainer}
          >
            <CustomSelect
                name='jobTitle'
                options={jobTitleOptions}
                onChange={handleJobTitleChange}
                isMulti={true}
                label={'Job Titles'}
                value={selectedJobTitles}
            />
            <AsyncCustomSelect
                value={selectedRepManagers}
                defaultOptions={employeeOptions}
                onChange={handleReportingManagerChange}
                loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
                isMulti
                label={'Reporting Manager'}
            />
            <CheckboxMultiSelect
                label='OrganizationUnit'
                name='organization'
                options={formattedOrgList}
                onChange={handleOrgUnitSelect}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={MultiSelectListItem}
                value={selectedOrgUnits}
                isMulti
            />
            <AsyncCustomSelect
                value={includedUsers}
                defaultOptions={employeeOptions}
                onChange={handleIncludedUserChange}
                loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
                isMulti
                label={'Included Users'}
            />
            <CustomSelect
                name='excludedUsers'
                options={excludeOptions}
                onChange={handleExcludedUserChange}
                isMulti={true}
                label={'Excluded Users'}
                value={excludedUsers}
            />
            <Button
                onClick={saveQuery}
                variant='primary'
                size='medium'
            >
              Save Query
            </Button>
          </div>
          { assignedUsers.length > 0 &&
          <table
              className={commonStyles.tableStyle}
          >
            <thead>
              <tr
                  className={commonStyles.tableHeader}
              >
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Employee Id
                  </SecondaryTypography.XSmall>
                </th>
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Name
                  </SecondaryTypography.XSmall>
                </th>
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Email
                  </SecondaryTypography.XSmall>
                </th>
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Score
                  </SecondaryTypography.XSmall>
                </th>
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Status
                  </SecondaryTypography.XSmall>
                </th>
              </tr>
            </thead>
            <tbody>
              { assignedUsers.length > 0 && assignedUsers.map((user) => {
                return (
                  <AssessmentUserRow
                      key={user.email}
                      assessmentUser={user}
                  />
                );
              })  }
            </tbody>
          </table>
          }
        </>
      ) }
    </>
  );
};
