import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../ui/button/button';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';

type UploadBase64ImageProps = {
    logo: string;
    setLogo: (base64Logo: string) => void;
}

export const UploadBase64Image = ({
  logo,
  setLogo,
}: UploadBase64ImageProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFileType = (file: File): boolean => {
    const validTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    return validTypes.includes(file.type);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (validateFileType(file)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Invalid file type. Please upload a jpg, png, or svg file.', { position: 'bottom-center' });
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (validateFileType(file)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setLogo(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('Invalid file type. Please upload a jpg, png, or svg file.', { position: 'bottom-center' });
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
        className='flex flex-col justify-center items-center border-2 border-primary-blue-500 border-dashed rounded-lg p-3'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{ height: '200px', width: '200px' }}
    >
      <input
          type='file'
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleFileSelect}
      />
      { logo ? (
        <>
          <img src={logo} alt='Company Logo'/>
          <Button onClick={() => setLogo('')}
              variant='danger'
              size='xSmall'
          >
            Clear logo
          </Button>
        </>
      )
        : (
          <>
            <SecondaryTypography.XSmall
                fontWeight='semi-bold'
            >
              Drag and drop to upload Company Logo
            </SecondaryTypography.XSmall>
            <Button
                onClick={handleClick}
                variant='primary'
                size='xSmall'
            >
              Add Company Logo
            </Button>
          </>
        )
      }
    </div>
  );
};
