import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AsyncCustomSelect from '../../../ui/custom_react_select/async_select';
import { FilterIcon } from '../../../ui/icons/icons';
import { TextInput } from '../../../ui/input/text_input/text_input';
import { PaginationNavigator } from '../../../ui/PaginationNavigator';
import { getEmployeeList } from '../../../utils/api_service/employees';
import { getJobOpeningList } from '../../../utils/api_service/JobOpenings';
import useDebouncedEmployeeSearch from '../../../utils/helpers/AsyncSelect/EmployeeGet';
import { EmployeeUser } from '../../../utils/types/admin_types';
import { JobOpeningList, JobOpeningRequest, sortJobOpening } from '../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../utils/types/react_select_types';
import JobOpeningTable from './JobOpeningTable';

export const JobOpeningsView = () => {
  const [jobOpeningData, setJobOpeningData] = useState<JobOpeningList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [defaultEmployeeUserList, setDefaultEmployeeUserList] = useState<EmployeeUser[]>([]);
  const [hiringManager, setHiringManager] = useState<SelectOptions>();
  const [internalRecruiter, setInternalRecruiter] = useState<SelectOptions>();
  const [jobReferenceNumber, setJobReferenceNumber] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [sort, setSort] = useState<sortJobOpening>({});
  const { loadOptionsDebounced } = useDebouncedEmployeeSearch();

  useEffect(() => {
    getEmployeeList('', 1, 10)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          response.json().then(errorData => {
            toast.error(errorData.error, { position: 'bottom-center' });
          });
        }
      })
      .then(data => {
        setDefaultEmployeeUserList(data.employee);
      })
      .catch(() => {
        toast.error('Error fetching employees', { position: 'bottom-center' });
      });
  }, []);

  useEffect(() => {
    const debounceSearchTerm = setTimeout(() => {
      if (pageNumber !== 1) {
        setPageNumber(1);
      } else {
        fetchJobApplicants(1);
      }
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [internalRecruiter, hiringManager, jobReferenceNumber, jobTitle, sort]);

  const defaultEmployeeUserOptions = defaultEmployeeUserList.map((employee) => ({
    value: employee.id,
    label: employee.user.firstName + ' ' + employee.user.lastName,
  }));

  const fetchJobApplicants = (page: number) => {
    setLoading(true);
    const requestBody: JobOpeningRequest = {
      jobTitle: jobTitle,
      jobReferenceNumber: jobReferenceNumber,
      hiringManager: hiringManager ? hiringManager.value : '',
      internalRecruiter: internalRecruiter ? internalRecruiter.value : '',
      sort: sort,
    };

    getJobOpeningList(requestBody, page, 10).then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    }).then((data) => {
      setJobOpeningData(data.jobOpenings);
      setTotalPages(Math.ceil(data.totalJobOpenings / 10));
    }).catch(() => {
      toast.error('Error Getting Job Applicants', { position: 'bottom-center' });
      setTotalPages(1);
      setJobOpeningData([]);
    }).finally(() => setLoading(false));
  };

  const handleManagerChange = (selectedOption: SelectOptions, hiringManager: boolean) => {
    if (hiringManager) {
      setHiringManager(selectedOption);
    } else {
      setInternalRecruiter(selectedOption);
    }
  };

  const onPageChange = (page: number) => {
    setPageNumber(page);
    fetchJobApplicants(page);
  };

  return (
    <div className='w-full min-h-screen p-4 flex-col'>
      <div className='flex-row flex gap-4 mb-6 items-center'>
        <div className='self-end'>
          <FilterIcon className='h-12 w-12 stroke-gray-500'/>
        </div>
        <div className='w-1/6'>
          <TextInput
              type='text'
              label='Job Title'
              name='jobTitle'
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
          />
        </div>
        <div className='w-1/6'>
          <TextInput
              type='text'
              label='Job Reference'
              name='jobReference'
              value={jobReferenceNumber}
              onChange={(e) => setJobReferenceNumber(e.target.value)}
          />
        </div>
        <div className='w-1/6'>
          <AsyncCustomSelect
              label='Hiring Manager'
              name='hiringManager'
              defaultOptions={defaultEmployeeUserOptions}
              onChange={(selectedOption: SelectOptions) => handleManagerChange(selectedOption, true)}
              loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
              isMulti={false}
          />
        </div>
        <div className='w-1/6'>
          <AsyncCustomSelect
              label='Internal Recruiter'
              name='internalRecruiter'
              defaultOptions={defaultEmployeeUserOptions}
              onChange={(selectedOption: SelectOptions) => handleManagerChange(selectedOption, false)}
              loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
              isMulti={false}
          />
        </div>
      </div>
      <div className='overflow-auto'>
        <JobOpeningTable jobOpeningData={jobOpeningData} loading={loading} sort={sort} setSort={setSort}/>
      </div>
      { (jobOpeningData.length > 0 && !loading) && (
        <div className='ml-auto mr-auto mt-5'>
          <PaginationNavigator
              pageNumber={pageNumber}
              maxPageNumber={totalPages}
              setPageNumber={onPageChange}
          />
        </div>
      ) }
    </div>
  );
};

