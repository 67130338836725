import React, { useState } from 'react';
import { Tabs } from '../../../../assessment/view/tabs/tabs';
import CompanyDetails from './CompanyDetails';
import JobPostDetails from './JobPostDetails';
import JobPostSettings from './JobPostSettings';

const JobPosting = () => {
  const [tabTitles, setTabTitles] = useState<string[]>(['Job Post Settings', 'Job Post Details', 'Company Details', 'Video Message Details', 'Preview']);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const renderTab = () => {
    if (activeTab === 0) {
      return (
        <JobPostSettings/>
      );
    } else if (activeTab === 1) {
      return (
        <JobPostDetails/>
      );
    } else if (activeTab === 2) {
      return (
        <CompanyDetails/>
      );
    }

  };

  return (
    <>
      <div>
        <Tabs
            tabTitles={tabTitles}
            setTabTitles={setTabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
        { renderTab() }
      </div>
    </>
  );
};

export default JobPosting;
