import React from  'react';
import { ActionMeta } from 'react-select';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { SelectOptions } from '../../../../../utils/types/react_select_types';

type AgeInputProps = {
  label: string
  selectOptions: SelectOptions[];
  onSelectChange: (selectOptions: SelectOptions, action: ActionMeta<unknown>) => void;
  selectValue: SelectOptions;
  onTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textValue: string;
  placeholder?: string;
  min: string;
  max: string;
  disabled?: boolean;
};

export const RangeSelectInput: React.FC<AgeInputProps> = ({
  label,
  selectOptions,
  onSelectChange,
  selectValue,
  onTextChange,
  textValue,
  placeholder,
  min,
  max,
  disabled = false,
}) => {
  return (
    <div className='flex gap-3'>
      <div className='w-40'>
        <div className='text-gray-800 py-1 text-sm'>
          { label }
        </div>
        <CustomSelect
            name={label}
            options={selectOptions}
            onChange={onSelectChange}
            value={selectValue}
            disabled={disabled}
        />
      </div>
      <div className='w-20 mt-7'>
        <TextInput
            name={label.toLowerCase()+'Number'}
            value={textValue}
            type='number'
            onChange={onTextChange}
            placeholder={placeholder}
            min={min}
            max={max}
            disabled={disabled}
        />
      </div>
    </div>
  );
};

