import React, { useEffect } from 'react';
import './app.css';
import { useDispatch } from 'react-redux';
import { Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AccessManagement } from './pages/admin/access_management/access_management';
import { AnalyticsSettings } from './pages/admin/AnalyticsSettings';
import { AssessmentFramework } from './pages/admin/AssessmentFramework';
import { CandidateManagement } from './pages/admin/CandidateManagement';
import { EmployeeManagement } from './pages/admin/EmployeeManagement';
import { OrganizationSettings } from './pages/admin/OrganizationSettings';
import { PartnerManagement } from './pages/admin/PartnerManagement';
import { AssessmentAnalytics } from './pages/analytics/AssessmentAnalytics';
import { EnterpriseAnalytics } from './pages/analytics/EnterpriseAnalytics';
import { OrganisationAnalytics } from './pages/analytics/OrganisationAnalytics';
import { CreateAssessmentPage } from './pages/assessment/create/create_assessment';
import { AssignedAssessments } from './pages/assessment/perform/assigned_assessments/assigned_assessments';
import { AssessmentList } from './pages/assessment/view/assessment_list/assessment_list';
import { ViewAssessment } from './pages/assessment/view/view_assessment/view_assessment';
import { ViewDetailedAssessmentResults } from './pages/assessment/view/view_assessment/view_detailed_assessment_results/view_detailed_assessment_results';
import { Home } from './pages/home/home';
import { JobOpeningsCreate } from './pages/job_openings/create';
import { JobOpeningsView } from './pages/job_openings/view';
import { JobPostingView } from './pages/job_openings/viewJobPosting';
import { ChangePassword } from './pages/login_register/change_password';
import { IdentityCheck } from './pages/login_register/identity_check';
import { Login } from './pages/login_register/login';
import { TermsConditions } from './pages/login_register/terms_conditions';
import { UserLanding } from './pages/login_register/user_landing';
import { Profile } from './pages/profile/Profile';
import { ContentManager } from './pages/questions/content_manager/content_manager';
import { QuestionBank } from './pages/questions/question_bank/question_bank';
import { QuestionGenerate } from './pages/questions/upload_file';
import { ConfirmNotice } from './pages/verify_email/confirm_notice/confirm_notice';
import { PageWrapper } from './ui/page_wrapper/page_wrapper';
import { getCurrentUser } from './utils/api_service/user_api';
import { getPermissions, isUserLoggedIn } from './utils/helpers/auth';
import { Permission } from './utils/helpers/permissions';
import { Features } from './utils/helpers/subscriptionEnum';
import { setPermissions } from './utils/redux_store/features/auth_permissions_reducer';
import { fetchJobTitles } from './utils/redux_store/features/job_title_reducer';
import { fetchSkillLevels } from './utils/redux_store/features/level_reducer';
import { fetchSkills } from './utils/redux_store/features/skill_reducer';
import { fetchSubscriptions } from './utils/redux_store/features/subscription_reducer';
import { fetchSubSkills } from './utils/redux_store/features/subskill_reducer';
import { setAccessibleViews, setCurrentView } from './utils/redux_store/features/ViewReducer';
import { store } from './utils/redux_store/store';
import { AuthenticatedRoute, Route } from './utils/routes/routes';
import 'react-toastify/dist/ReactToastify.css';
import { User } from './utils/types/assessment_types';
import { PreScreenBank } from './pages/questions/PreScreenBank';

const AssessmentPage = () => <PageWrapper Content={CreateAssessmentPage}/>;
const JobOpeningsPage = () => <PageWrapper Content={JobOpeningsCreate}/>;
const JobOpeningsViewPage = () => <PageWrapper Content={JobOpeningsView}/>;
const JobPostingViewPage = () => <PageWrapper Content={JobPostingView}/>;
const ContentManagerPage = () => <PageWrapper Content={ContentManager}/>;
const UploadFilePage = () => <PageWrapper Content={QuestionGenerate}/>;
const AssessmentListPage = () => <PageWrapper Content={AssessmentList}/>;
const ViewAssessmentPage = () => <PageWrapper Content={ViewAssessment}/>;
const AssignedAssessmentsPage = () => <PageWrapper Content={AssignedAssessments}/>;
const QuestionBankPage = () => <PageWrapper Content={QuestionBank}/>;
const PreScreenBankPage = () => <PageWrapper Content={PreScreenBank}/>;

const DetailedResultsPage =  () => <PageWrapper Content={ViewDetailedAssessmentResults}/>;
const HomePage =  () => <PageWrapper Content={Home}/>;
const ProfilePage =  () => <PageWrapper Content={Profile}/>;
const AccessManagementPage = () => <PageWrapper Content={AccessManagement}/>;
const EnterpriseAnalyticsPage = () => <PageWrapper Content={EnterpriseAnalytics}/>;
const OrganisationAnalyticsPage = () => <PageWrapper Content={OrganisationAnalytics}/>;
const AssessmentAnalyticsPage = () => <PageWrapper Content={AssessmentAnalytics}/>;
const EmployeeManagementPage = () => <PageWrapper Content={EmployeeManagement}/>;
const CandidateManagementPage= () => <PageWrapper Content={CandidateManagement}/>;
const PartnerManagementPage= () => <PageWrapper Content={PartnerManagement}/>;
const OrganizationSettingsPage = () => <PageWrapper Content={OrganizationSettings}/>;
const AnalyticsSettingsPage = () => <PageWrapper Content={AnalyticsSettings}/>;
const AssessmentFrameworkPage = () => <PageWrapper Content={AssessmentFramework}/>;

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (isUserLoggedIn() && token) {
      getCurrentUser().then((response: User) => {
        if (response.view) {
          dispatch(setCurrentView(response.view[0]));
          dispatch(setAccessibleViews(response.view));
        }
      });
      store.dispatch(fetchSkills());
      store.dispatch(fetchSkillLevels());
      store.dispatch(fetchSubSkills());
      store.dispatch(fetchJobTitles());
      store.dispatch(fetchSubscriptions());
      const permissions = getPermissions(token);
      dispatch(setPermissions(permissions));
    }
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route
            exact
            path='/'
        >
          <Redirect
              to='login'
          />
        </Route>
        <Route
            shouldRedirectIfLoggedIn={true}
            path='/login/:email?'
            component={Login}
        />
        <Route
            shouldRedirectIfLoggedIn={true}
            path='/verify/:token/:email/:firstname/:lastname'
            component={UserLanding}
        />
        <Route
            path='/verify/:token'
            component={ConfirmNotice}
        />
        <Route
            shouldRedirectIfLoggedIn={true}
            path='/identity-check/:token'
            component={IdentityCheck}
        />
        <Route
            shouldRedirectIfLoggedIn={true}
            path='/change-password/:token'
            component={ChangePassword}
        />
        <Route
            shouldRedirectIfLoggedIn={true}
            path='/terms-conditions/:token'
            component={TermsConditions}
        />
        <AuthenticatedRoute
            path='/identity-check'
            component={IdentityCheck}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/change-password'
            component={ChangePassword}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/user-profile'
            component={ProfilePage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/home'
            component={HomePage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/job-openings/:id?'
            component={JobOpeningsPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/job-openings-list'
            component={JobOpeningsViewPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/job-posting-list/:id?'
            component={JobPostingViewPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/assessment'
            component={AssessmentPage}
            requiredPermissions={[Permission.ASSESSMENTS_CREATE]}
        />
        <AuthenticatedRoute
            path='/assessment-list'
            component={AssessmentListPage}
            requiredPermissions={[Permission.ASSESSMENTS_VIEW]}
        />
        <AuthenticatedRoute
            path='/assigned-assessments'
            component={AssignedAssessmentsPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/question-bank'
            component={QuestionBankPage}
            requiredPermissions={[Permission.QUESTIONS_VIEW]}
        />
        <AuthenticatedRoute
            path='/pre-screen-bank'
            component={PreScreenBankPage}
            requiredPermissions={[Permission.QUESTIONS_VIEW]}
        />
        <AuthenticatedRoute
            path='/content-manager'
            component={ContentManagerPage}
            requiredPermissions={[Permission.JOB_VIEW]}
        />
        <AuthenticatedRoute
            path='/upload-file'
            component={UploadFilePage}
            requiredPermissions={[Permission.JOB_CREATE]}
        />
        <AuthenticatedRoute
            path='/view-assessment/:id/:tab?'
            component={ViewAssessmentPage}
            requiredPermissions={[Permission.ASSESSMENTS_VIEW]}
        />
        <AuthenticatedRoute
            path='/view-assessment-result/:assessmentUserId'
            component={DetailedResultsPage}
            requiredPermissions={[Permission.ASSESSMENTS_VIEW]}
        />
        <AuthenticatedRoute
            path='/access-management'
            component={AccessManagementPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/employee-management'
            component={EmployeeManagementPage}
            requiredPermissions={[Permission.EMPLOYEE_VIEW]}
            requiredFeature={Features.EMPLOYEE}
        />
        <AuthenticatedRoute
            path='/candidate-management'
            component={CandidateManagementPage}
            requiredPermissions={[Permission.CANDIDATE_VIEW]}
            requiredFeature={Features.CANDIDATE}
        />
        <AuthenticatedRoute
            path='/partner-management'
            component={PartnerManagementPage}
            requiredPermissions={[Permission.PARTNER_VIEW]}
            requiredFeature={Features.PARTNER}
        />
        <AuthenticatedRoute
            path='/organization-settings'
            component={OrganizationSettingsPage}
            requiredPermissions={[Permission.ORGUNIT_VIEW]}
        />
        <AuthenticatedRoute
            path='/terms-conditions'
            component={TermsConditions}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/analytics-settings'
            component={AnalyticsSettingsPage}
            requiredPermissions={[Permission.ENTERPRISE_ANALYTICS_EDIT]}
        />
        <AuthenticatedRoute
            path='/enterprise-analytics'
            component={EnterpriseAnalyticsPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/organisation-analytics'
            component={OrganisationAnalyticsPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/assessment-analytics'
            component={AssessmentAnalyticsPage}
            requiredPermissions={[]}
        />
        <AuthenticatedRoute
            path='/assessment-framework'
            component={AssessmentFrameworkPage}
            requiredPermissions={[]}
        />
      </Switch>
      <ToastContainer/>
    </Router>
  );
};
