import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import styles from '../../../../../ui/collapsible_question/collapsible_question.module.css';
import AsyncCustomSelect from '../../../../../ui/custom_react_select/async_select';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { PencilIcon, TrashIcon } from '../../../../../ui/icons/icons';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { setPreScreenQuestions } from '../../../../../utils/api_service/assessment_api';
import { searchPreScreenQuestions } from '../../../../../utils/api_service/pre_screen_api';
import { debounce } from '../../../../../utils/helpers/debounce';
import {
  Assessment,
  PreScreenQuestion,
  PreScreenQuestionBank,
  PreScreenQuestionType,
} from '../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';

const questionType: SelectOptions[] = [{
  label: 'Numeric',
  value: 'numeric',
}, {
  label: 'Multiple Choice',
  value: 'multiple-choice',
}];

type PreScreenQuestionsProps = {
  assessmentInfo: Assessment
  setAssessmentInfo: Dispatch<SetStateAction<Assessment | undefined>>
}

const PreScreenQuestions = ({ assessmentInfo, setAssessmentInfo }: PreScreenQuestionsProps) => {
  const [questions, setQuestions] = useState<PreScreenQuestion[]>(assessmentInfo.preScreen ? assessmentInfo.preScreen : []);
  const [newQuestionType, setNewQuestionType] = useState<PreScreenQuestionType>('numeric');
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [choices, setChoices] = useState<string[]>(['']);
  const [editQuestionType, setEditQuestionType] = useState<PreScreenQuestionType>('numeric');
  const [editQuestionText, setEditQuestionText] = useState<string>('');
  const [editChoices, setEditChoices] = useState<string[]>(['']);
  const [editIndex, setEditIndex] = useState<string | null>(null);
  const [searchQuestions, setSearchQuestions] = useState<PreScreenQuestionBank[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<SelectOptions[]>([]);

  const getQuestionTypeLabel = (value: string): string => {
    const found = questionType.find((type) => type.value === value);
    return found ? found.label : value;
  };

  const createLabel = (question: string, questionType: string) => {
    return (
      <div className='flex items-center'>
        <div className='w-10/12'>
          <span>{ question }</span>
        </div>
        <div className='w-1/12 text-left'>
          <span className='text-gray-500 text-sm'>{ getQuestionTypeLabel(questionType) }</span>
        </div>
      </div>
    );
  };

  const fetchOptions = async (inputValue: string): Promise<SelectOptions[]> => {
    try {
      const response = await searchPreScreenQuestions(inputValue, null, 1, 10);
      if (response.ok) {
        const data = await response.json();
        setSearchQuestions(data.questions);
        return data.questions.map((question: PreScreenQuestionBank) => ({
          value: question.id,
          label: createLabel(question.question, question.questionType),
        }));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
        return [];
      }
    } catch (error) {
      toast.error(`Error fetching questions: ${error}`, { position: 'bottom-center' });
      return [];
    }
  };

  useEffect(() => {
    const loadDefaultOptions = async () => {
      const options = await fetchOptions('');
      setDefaultOptions(options);
    };

    loadDefaultOptions();
  }, []);

  const loadOptionsDebounced = debounce(async (inputValue: string, callback: (options: SelectOptions[]) => void) => {
    const options = await fetchOptions(inputValue);
    callback(options);
  }, 1000);

  const generateUniqueKey = () => {
    return `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;
  };

  const handleAddQuestion = () => {
    if (!newQuestionText.trim()) return;

    const filteredChoices = choices.filter(choice => choice.trim() !== '');
    const newQuestion: PreScreenQuestion = {
      questionId: generateUniqueKey(),
      questionType: newQuestionType,
      question: newQuestionText,
      ...(newQuestionType === 'multiple-choice' && { choices: [...filteredChoices] }),
    };
    setQuestions([...questions, newQuestion]);
    setNewQuestionText('');
    setChoices(['']);
  };

  const handleChoiceChange = (index: number, value: string, edit: boolean) => {
    if (edit) {
      const newChoices = [...editChoices];
      newChoices[index] = value;
      setEditChoices(newChoices);
    } else {
      const newChoices = [...choices];
      newChoices[index] = value;
      setChoices(newChoices);
    }
  };

  const handleDeleteQuestion = (id: string) => {
    setQuestions(questions.filter(question => question.questionId !== id));
  };

  const handleSaveQuestion = () => {
    if (editIndex !== null) {
      const updatedQuestions = questions.map((question) =>
        question.questionId === editIndex
          ? {
            ...question,
            question: editQuestionText,
            questionType: editQuestionType,
            choices: editQuestionType === 'multiple-choice' ? editChoices.filter(choice => choice.trim() !== '') : undefined,
          }
          : question,
      );
      setQuestions(updatedQuestions);
      setEditIndex(null);
      setEditQuestionText('');
      setEditChoices(['']);
    }
  };

  const handleSaveQuestions = async () => {
    try {
      const response = await setPreScreenQuestions(assessmentInfo.id, questions);
      const assessment: Assessment = await response.json();
      setAssessmentInfo(assessment);
      toast.success('Saved Pre Screen Questions', { position: 'bottom-center' });
    } catch (error) {
      toast.error('Failed to Save. Try Again', { position: 'bottom-center' });
    }
  };

  const handleEditQuestion = (question: PreScreenQuestion) => {
    setEditIndex(question.questionId);
    setEditQuestionText(question.question);
    setEditQuestionType(question.questionType);
    setEditChoices(question.choices || ['']);
  };

  const handleQuestionChange = (selectedOption: SelectOptions) => {
    const preScreenQuestion = searchQuestions.find(question => question.id === selectedOption.value);
    if (preScreenQuestion && preScreenQuestion.id) {
      const duplicateQuestion = questions.find(question => question.questionId === selectedOption.value);
      if (duplicateQuestion) {
        toast.error('Question already exists in assessment', { position: 'bottom-center' });
        return;
      }
      let newQuestion: PreScreenQuestion = {
        questionId: preScreenQuestion.id,
        questionType: preScreenQuestion.questionType,
        question: preScreenQuestion.question,
      };
      if (preScreenQuestion.questionType === 'multiple-choice' && preScreenQuestion.choices) {
        newQuestion.choices = preScreenQuestion.choices;
      }
      setQuestions([...questions, newQuestion]);
    }
  };

  return (
    <div className='flex-col p-4 relative'>
      <div>
        <AsyncCustomSelect
            name='preScreenQuestion'
            value={[]}
            placeholder='Search Question In Bank'
            defaultOptions={defaultOptions}
            onChange={handleQuestionChange}
            loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
        />
      </div>
      <div className='flex gap-4 mt-8'>
        <div className='w-8/12'>
          <TextInput
              label='Question'
              name='question'
              value={newQuestionText}
              type='text'
              onChange={(e) => setNewQuestionText(e.target.value)}
              disabled={editIndex !== null}
          />
        </div>
        <div className='w-4/12'>
          <CustomSelect
              label='Question Type'
              name='questionType'
              value={questionType.find(option => option.value === newQuestionType)}
              options={questionType}
              onChange={(selectedOption: SelectOptions) => setNewQuestionType(selectedOption.value as 'numeric' | 'multiple-choice')}
              disabled={editIndex !== null}
          />
        </div>
      </div>
      { newQuestionType === 'multiple-choice' && (
        <div className='mt-2'>
          { choices.map((choice, index) => (
            <div key={index} className='flex'>
              <div className='w-full mt-[6px]'>
                <TextInput
                    name='choice'
                    value={choice}
                    type='text'
                    onChange={(e) => handleChoiceChange(index, e.target.value, false)}
                />
              </div>
              <div>
                { index === choices.length - 1 && (
                  <Button
                      onClick={() => setChoices([...choices, ''])}
                      variant='secondary'
                      size='small'
                  >
                    Add Choice
                  </Button>
                ) }
              </div>
            </div>
          )) }
        </div>
      ) }
      <Button
          onClick={() => handleAddQuestion()}
          variant='primary'
          size='xSmall'
          disabled={newQuestionText === '' || (newQuestionType === 'multiple-choice' && choices.every(choice => choice.trim() === '')) || editIndex !== null}
      >
        Add Question
      </Button>
      { questions.length > 0 && (
        <div className='flex flex-col gap-4 py-5 mb-10'>
          { questions.map((question, indexNum) => (
            <div key={question.questionId}
                className={`flex flex-col gap-1 items-center mb-2 w-full bg-gray-100 p-5 rounded-lg border-2 ${question.questionId === editIndex && 'border-green-400'}`}>
              { question.questionId === editIndex ? (
                <>
                  <div className='flex gap-4 w-full'>
                    <div className='w-8/12'>
                      <TextInput
                          label={`${indexNum + 1}. Question`}
                          name='question'
                          value={editQuestionText}
                          type='text'
                          onChange={(e) => setEditQuestionText(e.target.value)}
                      />
                    </div>
                    <div className='w-4/12'>
                      <CustomSelect
                          label='Question Type'
                          name='questionType'
                          value={questionType.find(option => option.value === editQuestionType)}
                          options={questionType}
                          onChange={(selectedOption: SelectOptions) => setEditQuestionType(selectedOption.value as 'numeric' | 'multiple-choice')}
                      />
                    </div>
                  </div>
                  { editQuestionType === 'multiple-choice' && (
                    <div className='mt-2 w-full'>
                      <div className='text-[#6B7280]'>
                        <SecondaryTypography.XSmall fontWeight='semi-bold'>
                          Options:
                        </SecondaryTypography.XSmall>
                      </div>
                      { editChoices.map((choice, index) => (
                        <div key={index} className='flex w-full'>
                          <div className='w-full mt-[6px]'>
                            <TextInput
                                name='choice'
                                value={choice}
                                type='text'
                                onChange={(e) => handleChoiceChange(index, e.target.value, true)}
                            />
                          </div>
                          <div>
                            { index === editChoices.length - 1 && (
                              <Button
                                  onClick={() => setEditChoices([...editChoices, ''])}
                                  variant='secondary'
                                  size='small'
                              >
                                Add Choice
                              </Button>
                            ) }
                          </div>
                        </div>
                      )) }
                    </div>
                  ) }
                  <div className='self-end'>
                    <Button
                        onClick={() => handleSaveQuestion()}
                        variant='primary'
                        size='large'
                    >
                      Save
                    </Button>
                  </div>

                </>
              ) : (
                <>
                  <div className='flex w-full gap-4'>
                    <div className='w-8/12'>
                      <TextInput
                          label={`${indexNum + 1}. Question`}
                          name={`question-${question.questionId}`}
                          value={question.question}
                          type='text'
                          disabled={true}
                          onChange={() => {
                          }}
                      />
                    </div>
                    <div className='w-3/12'>
                      <TextInput
                          label='Question Type'
                          name={`question-type-${question.questionId}`}
                          value={questionType.find(option => option.value === question.questionType)?.label || ''}
                          type='text'
                          disabled={true}
                          onChange={() => {
                          }}
                      />
                    </div>
                    <div
                        className={`${classNames(styles.icons, styles.editIcon, styles.clickable)} self-center py-1`}
                        onClick={() => handleEditQuestion(question)}
                    >
                      <PencilIcon className='h-8 w-8'/>
                    </div>
                    <div
                        className={`${classNames(styles.icons, styles.trashIcon, styles.clickable)} self-center py-1`}
                        onClick={() => handleDeleteQuestion(question.questionId)}

                    >
                      <TrashIcon className='h-8 w-8'/>
                    </div>
                  </div>
                  { question.questionType === 'multiple-choice' && (
                    <div className='w-full flex flex-col gap-1'>
                      <div className='text-[#6B7280] mt-[6px]'>
                        <SecondaryTypography.XSmall fontWeight='semi-bold'>
                          Options:
                        </SecondaryTypography.XSmall>
                      </div>
                      { question.choices?.map((choice, idx) => (
                        <div key={idx} className='w-full'>
                          <TextInput
                              name={`choice-${question.questionId}-${idx}`}
                              value={choice}
                              type='text'
                              disabled={true}
                              onChange={() => {
                              }}
                          />
                        </div>
                      )) }
                    </div>
                  ) }
                </>
              ) }
            </div>
          )) }
        </div>
      ) }
      <div className='absolute -bottom-10 right-0'>
        <Button
            onClick={() => handleSaveQuestions()}
            variant='primary'
            size='xSmall'
        >
          Save Pre Screen Questions
        </Button>
      </div>
    </div>
  );
};

export default PreScreenQuestions;
