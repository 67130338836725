import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateJobApplicantStatus } from '../../../../../utils/api_service/JobOpenings';
import { SegmentType } from '../../../../../utils/types/JobOpenings';

type AnalyticActionProps = {
    actionSelected: string[],
    setActionSelected: (value: string[]) => void,
    setSegmentedData: (value: SegmentType[][]) => void,
    defaultSegment: SegmentType[][],
}

export const AnalyticAction = ({
  actionSelected,
  setActionSelected,
  setSegmentedData,
  defaultSegment,
}:AnalyticActionProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams<{ id: string }>();

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const editApplicants = async (requestObject: { jobApplicantIds: string[]; applicantStatus: string }) => {
    try {
      await updateJobApplicantStatus(id, requestObject);
      setActionSelected([]);
      setSegmentedData(defaultSegment);
    } catch (error) {
      toast.error('Error Changing applicant status', { position: 'bottom-center' });
    }
  };

  const handleOptionClick = (optionValue: string) => {
    const requestObject = {
      jobApplicantIds: actionSelected,
      applicantStatus: optionValue,
    };

    editApplicants(requestObject);
    setIsOpen(false);
  };

  const statusList = [
    {
      'value': 'New',
      'label': 'New',
    },
    {
      'value': 'Suitable',
      'label': 'Suitable',
    },
    {
      'value': 'Auto-Assessment',
      'label': 'Auto Assess',
    },
    {
      'value': 'Outstanding',
      'label': 'Outstanding',
    },
    {
      'value': 'Shortlisted',
      'label': 'ShortList',
    },
    {
      'value': 'Unsuccessful-Closed',
      'label': 'Unsuccessful',
    },
  ];

  return (
    <div
        className='mt-12 flex flex-col gap-8 border-b border-gray-200'
    >
      <div className='mt-8 dropdown inline-block relative'>
        <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
            onClick={handleButtonClick}
        >
          <span>Move to</span>
          <span className='ml-5'>&#9662;</span> { /* Added ml-1 for left margin */ }
        </button>
        { isOpen && (
          <div className='dropdown-content absolute bg-white border rounded mt-2 w-32 shadow-md z-10'>
            { statusList.map((status, index) => (
              <p
                  key={index}
                  className='py-2 px-4 cursor-pointer hover:bg-gray-200'
                  onClick={() => handleOptionClick(status.value)}
              >
                { status.label }
              </p>
            )) }
          </div>
        ) }
      </div>
    </div>
  );
};
