import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getJob  } from '../../../../utils/api_service/job_api';
import { ContextBasedGeneratorJob } from '../../../../utils/types/content_manager_types';
import commonStyles from '../../questions.module.css';
import { QuestionsList } from '../question_list';
import { UploadDocumentDropbox } from '../upload_document_dropbox/upload_document_dropbox';

export const UploadFile = () => {
  const questionsCount = useRef(0);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
  const [errorJob, setErrorJob] = useState<boolean>(false);
  const [job, setJob] = useState<ContextBasedGeneratorJob>({
    id: '',
    generationType: '',
    _cls: '',
    title: '',
    organizationId: '',
    status: 'Uploading...',
    creationDateTime: 0,
    topic: '',
    questions: [],
  });

  const pollJob = (id: string) => {
    getJob(id).then(response => {
      setJob(response);
      if (response.status && response.status.includes('Error')) {
        setErrorJob(true);
        setQuestionsLoading(false);
        toast.error(`${response.status} Please Try Again`, { position: 'bottom-center' });
      } else {
        questionsCount.current = (response.questions?.length || 0);
        if (questionsCount.current === 0) {
          setTimeout(() => {
            pollJob(id);
          }, 5000);
        } else {
          setQuestionsLoading(false);
        }
      }
    }).catch(() => {
      toast.error('Error. Please try again', { position: 'bottom-center' });
      setErrorJob(true);
      setQuestionsLoading(false);
    });
  };

  return (
    <div>
      <div
          className={commonStyles.contentContainer}
      >
        <UploadDocumentDropbox
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            pollJobStatus={pollJob}
            setJob={setJob}
            setQuestionLoading={setQuestionsLoading}
            questionLoading={questionsLoading}
            job={job}
        />
        <div className='mt-5'>
          <QuestionsList
              job={job}
              questionsLoading={questionsLoading}
              setJob={setJob}
              errorJob={errorJob}
          />
        </div>
      </div>
    </div>
  );
};
