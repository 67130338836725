import React, { useEffect, useRef, useState } from 'react';
import { AutoEvaluationLabel } from '../../../../../utils/types/JobOpenings';

interface Box {
  id: number;
  label: AutoEvaluationLabel;
  isEditing?: boolean;
}

interface AddRemoveComponentProps {
  initialLabels: AutoEvaluationLabel[];
  onLabelsChange: (labels: AutoEvaluationLabel[]) => void;
  disabled?: boolean;
}

const AddOrRemoveListWithOperators = ({ initialLabels, onLabelsChange, disabled }: AddRemoveComponentProps) => {
  const [boxes, setBoxes] = useState<Box[]>(() => {
    return initialLabels.map((label, index) => ({ id: index, label, isEditing: false }));
  });
  const [count, setCount] = useState(() => {
    return initialLabels.length;
  });
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const labels = boxes.map(box => box.label);
    onLabelsChange(labels);
  }, [boxes]);

  const handleToggleOperator = () => {
    setBoxes(boxes.map(box => {
      return { ...box, label: { ...box.label, relationToNext: box.label.relationToNext === 'AND' ? 'OR' : 'AND' } };
    }));
  };

  const handleAddBox = () => {
    if (inputValue.trim()) {
      const newBox: Box = {
        id: count,
        label: {
          value: inputValue,
          relationToNext: 'AND',
        },
        isEditing: false,
      };
      setBoxes([...boxes, newBox]);
      setInputValue('');
      setCount(prevCount => prevCount + 1);
    }
    setShowInput(false);
  };

  const handleRemove = (id: number) => {
    setBoxes(boxes.filter(box => box.id !== id));
  };

  const handleStartEdit = (id: number) => {
    setBoxes(boxes.map(box => {
      if (box.id === id) {
        return { ...box, isEditing: true };
      }
      return box;
    }));
  };

  const handleStopEdit = (id: number, newLabel: string) => {
    setBoxes(boxes.map((box) => {
      if (box.id === id) {
        return {
          ...box,
          label: {
            value: newLabel,
            relationToNext: box.label.relationToNext,
          },
          isEditing: false,
        };
      }
      return box;
    }));
  };

  const handleClickAdd = () => {
    setShowInput(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  return (
    <div>
      <div className='flex flex-wrap gap-2 items-center'>
        { boxes.map((box, index) => (
          <React.Fragment
              key={box.id}
          >
            <div
                className={`relative flex gap-x-2 items-center ${disabled && 'bg-gray-100'} px-4 py-2 border ${box.isEditing ? 'border-primary-blue-500' : 'border-gray-200'} mr-2 rounded-md`}
            >
              { box.isEditing ? (
                <input
                    type='text'
                    className='w-full rounded outline-none text-sm'
                    style={{ minWidth: '450px' }}
                    defaultValue={box.label.value}
                    onBlur={(e) => handleStopEdit(box.id, e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleStopEdit(box.id, e.currentTarget.value)}
                    autoFocus
                />
              ) : (
                <div className={`flex-1 pr-4 ${!disabled && 'cursor-pointer'} text-sm`} onClick={() => {(!disabled && handleStartEdit(box.id));}}>
                  { box.label.value }
                </div>
              ) }
              { !disabled && (
                <button onClick={() => handleRemove(box.id)}
                    className='absolute right-0 hover:bg-red-200 p-2 rounded-r-sm'>
                  x
                </button>
              ) }
            </div>
            { index < boxes.length - 1 && (
              <button onClick={() => !disabled && handleToggleOperator()}
                  className={`text-sm text-white mr-2 p-1 cursor-default bg-primary-blue-500 font-medium rounded ${!disabled && 'cursor-pointer'}`}>
                { box.label.relationToNext }
              </button>
            ) }
          </React.Fragment>
        )) }
        { showInput && (
          <input
              ref={inputRef}
              type='text'
              className='px-4 py-2 mr-2 rounded outline-none border border-primary-blue-500 text-sm'
              style={{ minWidth: '450px' }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleAddBox()}
              onBlur={handleAddBox}
          />
        ) }
        { (!showInput && !disabled) && (
          <button onClick={handleClickAdd}
              className='bg-primary-blue-500 text-white font-bold py-2 px-4 rounded text-sm shadow-lg shadow-primary-blue-400'>
            +
          </button>
        ) }
      </div>
    </div>
  );
};

export default AddOrRemoveListWithOperators;
