import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import HoverInfo from '../../../../ui/HoverInfo';
import { SortDown, SortUp } from '../../../../ui/icons/icons';
import TableSkeletonLoader from '../../../../ui/TableSkeletonLoader';
import { unixToCurrentTimezone } from '../../../../utils/helpers/time';
import { JobApplicant, JobOpeningList, sortJobOpening } from '../../../../utils/types/JobOpenings';

export type TableColumn = {
  title: string;
  width: string;
};

export type JobOpeningTableProps = {
  jobOpeningData: JobOpeningList[],
  loading: boolean,
  sort: sortJobOpening,
  setSort: Dispatch<SetStateAction<sortJobOpening>>,
}

type MappingTitleType = {
  'Job Title': string,
  'JD Reference': string,
  'Hiring Manager': string,
  'Internal Recruiter': string,
  'Applicants': string,
  'Creation Date': string,
  'Expiry Date': string,
}

const MappingTitle = {
  'Job Title': 'jobTitle',
  'JD Reference': 'jobReference',
  'Hiring Manager': 'hiringManager',
  'Internal Recruiter': 'internalRecruiter',
  'Applicants': 'applicants',
  'Creation Date': 'creationDate',
  'Expiry Date': 'expiryDate',
};

const JobOpeningTable = ({
  jobOpeningData,
  loading,
  sort,
  setSort,
}: JobOpeningTableProps) => {
  const history = useHistory();
  const columns: TableColumn[] = [
    { title: 'Job Title', width: 'w-2/12' },
    { title: 'JD Reference', width: 'w-1/12' },
    { title: 'Hiring Manager', width: 'w-1/12' },
    { title: 'Internal Recruiter', width: 'w-1/12' },
    { title: 'Applicants', width: 'w-1/12' },
    { title: 'Creation Date', width: 'w-1/12' },
    { title: 'Expiry Date', width: 'w-1/12' },
  ];

  const handleSort = (column: string, sorting: 'asc' | 'desc') => {
    setSort(prevSortObject => {
      const newSortDict = { ...prevSortObject };
      const columnKey = MappingTitle[column as keyof MappingTitleType];
      if (newSortDict[columnKey as keyof sortJobOpening]) {
        if (newSortDict[columnKey as keyof sortJobOpening] === sorting) {
          delete newSortDict[columnKey as keyof sortJobOpening];
        } else {
          newSortDict[columnKey as keyof sortJobOpening] = sorting;
        }
      } else {
        newSortDict[columnKey as keyof sortJobOpening] = sorting;
      }
      return newSortDict;
    });
  };

  const renderRow = (title: string, row: any, column: any) => {
    switch (title) {
      case 'Hiring Manager':
        return (
          <div className='flex justify-start text-left'>
            <HoverInfo
                message={row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant].join(', ')}
                position='top'
                messageClassName='bg-white text-gray-500 border-2 border-gray-200 text-md w-56 text-center text-sm'
            >
              { row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant][0] }
            </HoverInfo>
          </div>
        );
      case 'Internal Recruiter':
        return (
          <div className='flex justify-start text-left'>
            <HoverInfo
                message={row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant].join(', ')}
                position='top'
                messageClassName='bg-white text-gray-500 border-2 border-gray-200 text-md w-56 text-center text-sm'
            >
              { row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant][0] }
            </HoverInfo>
          </div>
        );
      case 'Creation Date':
        return (
          <div className='flex justify-start text-left text-green-600'>
            { unixToCurrentTimezone(row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant] as number, false) }
          </div>
        );
      case 'Expiry Date':
        return (
          <div className='flex justify-start text-left text-red-400'>
            { unixToCurrentTimezone(row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant] as number, false) }
          </div>
        );
      case 'Applicants':
        return (
          <div className='flex justify-start text-left'>
            { row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant] }
          </div>
        );
      default:
        return (
          <div className='flex justify-start text-left'>
            { row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant] ? row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant] : 'Undefined' }
          </div>
        );
    }
  };

  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr>
            { columns.map((column, index) => (
              <th key={index} className={`border-b-2 px-4 py-3 ${column.width} font-semibold text-gray-500`}>
                <div className='flex justify-start gap-2'>
                  { column.title }
                  { ['Creation Date', 'Expiry Date', 'Job Title'].includes(column.title) && (
                    <div className='flex flex-row justify-center items-center cursor-pointer'>
                      <div onClick={() => handleSort(column.title, 'asc')}>
                        <SortUp className={`w-4 h-5 ${sort[MappingTitle[column.title as keyof MappingTitleType] as keyof sortJobOpening] === 'asc' ? 'fill-primary-blue-500' : ''}`}/>
                      </div>
                      <div onClick={() => handleSort(column.title, 'desc')}>
                        <SortDown className={`w-4 h-5 ${sort[MappingTitle[column.title as keyof MappingTitleType] as keyof sortJobOpening] === 'desc' ? 'fill-primary-blue-500' : ''}`}/>
                      </div>
                    </div>
                  ) }
                </div>
              </th>
            )) }
          </tr>
        </thead>
        { !loading && (
          <tbody>
            { jobOpeningData.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => {history.push(`/job-openings/${row['id']}`);}}
                  className='cursor-pointer hover:bg-gray-100'>
                { columns.map((column, colIndex) => (
                  <React.Fragment key={colIndex}>
                    <td className={`border-b-2 px-4 py-3 ${column.width} `}>
                      { renderRow(column.title, row, column) }
                    </td>
                  </React.Fragment>
                )) }
              </tr>
            )) }
          </tbody>
        ) }
      </table>
      { loading && <TableSkeletonLoader numRows={10} height={12}/> }
    </div>
  );
};

export default JobOpeningTable;
