import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { PrimaryTypography, SecondaryTypography } from '../../../../ui/typography/typography';
import { createJob, getJob } from '../../../../utils/api_service/job_api';
import { formatDateTime } from '../../../../utils/helpers/time';
import { Settings, TopicBasedGeneratorJob } from '../../../../utils/types/content_manager_types';
import commonStyles from '../../questions.module.css';
import styles from '../ContextSection/upload-file.module.css';
import { GenerateProperties } from '../GenerateProperties/GenerateProperties';
import { QuestionsList } from '../question_list';
import { SessionTitle } from '../session_title';
import { TopicInput } from '../topic_input';

export const TopicQuestionGenerate = () => {
  const questionsCount = useRef(0);
  const [topic, setTopic] = useState<string>('');
  const [defaultSessionTitle, setDefaultSessionTitle] = useState<string>(`${topic ? topic : ''}-`);
  const [sessionTitle, setSessionTitle] = useState<string>(defaultSessionTitle);
  const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
  const [errorJob, setErrorJob] = useState<boolean>(false);

  useEffect(() => {
    const currentDateTimeString = formatDateTime(new Date());
    setDefaultSessionTitle(`${topic ? topic : ''}-${currentDateTimeString}`);
  }, [topic]);

  const [editGenerateProperties, setEditGenerateProperties] = useState<Settings>({
    level: '',
    jobTitle: '',
    questionType: '',
    time: 0,
    points: 0,
  });
  const [job, setJob] = useState<TopicBasedGeneratorJob>({
    id: '',
    generationType: '',
    _cls: '',
    title: '',
    organizationId: '',
    status: 'Uploading...',
    creationDateTime: 0,
    topic: '',
    questions: [],
  });

  const pollJob = (id: string) => {
    getJob(id).then(response => {
      setJob(response);
      if (response.status && response.status.includes('Error')) {
        setQuestionsLoading(false);
        setErrorJob(true);
        toast.error(`${response.status} Please Try Again`, { position: 'bottom-center' });
      } else {
        questionsCount.current = (response.questions?.length || 0);
        if (questionsCount.current === 0) {
          setTimeout(() => {
            pollJob(id);
          }, 5000);
        } else {
          setQuestionsLoading(false);
        }
      }
    }).catch(() => {
      toast.error('Error. Please try again', { position: 'bottom-center' });
      setErrorJob(true);
    });
  };

  const generateQuestions = () => {
    setQuestionsLoading(true);
    setErrorJob(false);
    createJob(sessionTitle, topic, 'topic', editGenerateProperties).then(response => {
      if (response.questions && response.questions.length !== 0) {
        setJob(response);
        setQuestionsLoading(false);
      } else {
        pollJob(response.id);
      }
    });
  };

  return (
    <div>
      <div className={commonStyles.contentContainer}>
        <SessionTitle
            sessionTitle={sessionTitle}
            setSessionTitle={setSessionTitle}
            defaultValue={defaultSessionTitle}
            disabled={questionsLoading || (job.questions && job.questions.length > 0)}
        />
        <PrimaryTypography.TwoXLarge
            className={classNames(styles.headerText, styles.blueText)}
            fontWeight='bold'
        >
          Enter the topic below
        </PrimaryTypography.TwoXLarge>
        <div className='w-1/2 mb-4'>
          <TopicInput
              setTopic={setTopic}
              disabled={questionsLoading || (job.questions && job.questions.length > 0)}
              placeholder='Type a skill and press enter...'
          />
          <SecondaryTypography.Small className='text-gray-500 pt-1'>
            For sub skill creation press enter after each parent skill (maximum of 1 skill and 3 sub skills)
          </SecondaryTypography.Small>
        </div>
        <GenerateProperties
            setEditGenerateProperties={setEditGenerateProperties}
            job={job}
        />
        <div className='mt-5 w-40'>
          <Button
              onClick={() => generateQuestions()}
              variant='secondary'
              size='xSmall'
              disabled={editGenerateProperties.jobTitle === '' || topic === '' || sessionTitle === '' || editGenerateProperties.level === '' || (job.questions && job.questions.length > 0) || questionsLoading}
          >
            Generate Questions
          </Button>
        </div>
        <div className='mt-5'>
          <QuestionsList
              job={job}
              questionsLoading={questionsLoading}
              setJob={setJob}
              errorJob={errorJob}
          />
        </div>
      </div>
    </div>
  );
};

