import React from 'react';
import { jobOpeningPages } from '../../../../../utils/types/JobOpenings';

// Define the types for the component props
type ChevronProps = {
  steps: { label: jobOpeningPages; status: 'active' | 'non-active' | 'disabled' }[];
  setCurrentPage: (page: jobOpeningPages) => void;
};

// Chevron component
const Chevron: React.FC<ChevronProps> = ({ steps, setCurrentPage }) => {
  const colorMappings : {[key: string]: string} = {
    'active': 'bg-primary-green-500',
    'non-active': 'bg-primary-green-300',
    'disabled': 'bg-gray-400',
  };

  return (
    <div>
      <ol className='grid grid-cols-1 sm:grid-cols-5 divide-x divide-gray-100 overflow-hidden rounded-lg border text-md text-white -z-20'>
        { steps.map((step, index) => (
          <li
              onClick={() => {
                if (step.status === 'non-active') {
                  setCurrentPage(step.label);
                }
              }}
              key={index}
              className={`relative flex items-center justify-center gap-2 p-4 ${colorMappings[step.status]} ${step.status !== 'disabled' && 'hover:cursor-pointer'}`}
          >
            { index !== 0 && (
              <div className={'-z-0'}>
                <span
                    className={`absolute -left-5 top-1/2 hidden h-14 w-14 -translate-y-1/2 rotate-45 border-t-2 border-r-2  sm:block ${colorMappings[steps[index-1].status]}`}
                ></span>
              </div>
            ) }
            <div className={'z-0'}>
              <p className='leading-none'>
                <strong className='block font-medium'>{ step.label }</strong>
              </p>
            </div>
          </li>
        )) }
      </ol>
    </div>
  );
};

export default Chevron;
