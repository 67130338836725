import React from 'react';
import { Range, getTrackBackground } from 'react-range';

type AnalyticThresholdProps = {
    setExperienceValue: (value: number[]) => void,
    skillValue: number[],
    setSkillValue: (value: number[]) => void,
    experienceValue: number[],
    expTitle: string,
    skillTitle: string,
}

export const AnalyticThreshold = ({
  setExperienceValue,
  skillValue,
  setSkillValue,
  experienceValue,
  expTitle,
  skillTitle,
}: AnalyticThresholdProps) => {

  return (
    <div className='text-[13.5px] mt-14 w-[250px] flex flex-col gap-2 mr-10'>
      <output className='mt-6' id='output'>
        { skillTitle } ({ skillValue[0] } - { skillValue[1] })
      </output>
      <Range
          values={skillValue}
          step={1}
          min={0}
          max={40}
          onChange={(newValues) => setSkillValue(newValues)}
          renderTrack={({ props, children }) => (
            <div
                {...props}
                className='h-1.5 w-full self-center'
                style={{
                  ...props.style,
                  background: getTrackBackground({
                    values: skillValue,
                    colors: ['#ccc', '#548BF4', '#ccc'],
                    min: 0,
                    max: 40,
                  }),
                }}
            >
              { children }
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
                {...props}
                className='h-6 w-6 rounded-full bg-white flex justify-center items-center shadow-md'
                style={props.style}
            >
              <div
                  className={`h-4 w-1 ${
                    isDragged ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
              />
            </div>
          )}
      />
      <output className='mt-6' id='output'>
        { expTitle } ({ experienceValue[0] } - { experienceValue[1] })
      </output>
      <Range
          values={experienceValue}
          step={1}
          min={0}
          max={40}
          onChange={(newValues) => setExperienceValue(newValues)}
          renderTrack={({ props, children }) => (
            <div
                {...props}
                className='h-1.5 w-full self-center'
                style={{
                  ...props.style,
                  background: getTrackBackground({
                    values: experienceValue,
                    colors: ['#ccc', '#548BF4', '#ccc'],
                    min: 0,
                    max: 40,
                  }),
                }}
            >
              { children }
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
                {...props}
                className='h-6 w-6 rounded-full bg-white flex justify-center items-center shadow-md'
                style={props.style}
            >
              <div
                  className={`h-4 w-1 ${
                    isDragged ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
              />
            </div>
          )}
      />
    </div>
  );
};

