import React, { ReactNode } from 'react';

type HoverInfoProps = {
  message: string,
  position: 'top' | 'bottom' | 'left' | 'right',
  children: ReactNode,
  className?: string,
  messageClassName?: string
}

export default function HoverInfo({
  message,
  position,
  children,
  className = '',
  messageClassName='bg-white text-gray-500 border-2 border-gray-200 text-md w-56',
}: HoverInfoProps) {
  const positionClasses = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 ml-2',
  };

  return (
    <div className={`group relative flex ${className}`}>
      { children }
      <span
          className={`absolute overflow-visible ${positionClasses[position]} text-center text-sm scale-0 transition-transform duration-200 ease-in-out rounded p-2 group-hover:scale-100 ${messageClassName}`}
      >
        { message }
      </span>
    </div>
  );
}
