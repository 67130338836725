import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { AddIcon } from '../../../../ui/icons/icons';
import { TextInput } from '../../../../ui/input/text_input/text_input';
import { PaginationNavigator } from '../../../../ui/PaginationNavigator';
import TableSkeletonLoader from '../../../../ui/TableSkeletonLoader';
import { createPreScreenQuestion, searchPreScreenQuestions } from '../../../../utils/api_service/pre_screen_api';
import { PreScreenQuestionBank, PreScreenQuestionType } from '../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import { PreScreenQuestion } from '../PreScreenQuestion';

export const questionTypeOptions: SelectOptions[] = [{
  label: 'Numeric',
  value: 'numeric',
}, {
  label: 'Multiple Choice',
  value: 'multiple-choice',
}];

export const PreScreenBankSearch = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [questionType, setQuestionType] = useState<PreScreenQuestionType | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [questionsList, setQuestionsList] = useState<PreScreenQuestionBank[]>([]);
  const [newQuestion, setNewQuestion] = useState<boolean>(false);
  const [newQuestionType, setNewQuestionType] = useState<PreScreenQuestionType>('numeric');
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [newQuestionDefault, setNewQuestionDefault] = useState<boolean>(false);
  const [choices, setChoices] = useState<string[]>(['']);

  useEffect(() => {
    const debounceSearchTerm = setTimeout(async () => {
      setLoading(true);

      try {
        const response = await searchPreScreenQuestions(searchTerm, questionType as PreScreenQuestionType, pageNumber, 10);
        if (!response.ok) {
          throw new Error();
        }
        const result = await response.json();
        setQuestionsList(result.questions);
        setPageNumber(result.page);
        setTotalPages(Math.ceil(result.total / 10));
        setTotalQuestions(result.total);
      } catch (error) {
        toast.error('Error fetching questions', { position: 'bottom-center' });
      } finally {
        setLoading(false);
      }
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchTerm, questionType]);

  const handleChoiceChange = (index: number, value: string) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const validateQuestionData = (
    questionText: string,
    questionType: PreScreenQuestionType,
    choices: string[],
    defaultQuestion: boolean,
  ) => {
    const trimmedQuestionText = questionText.trim();
    const filteredChoices = choices.filter(choice => choice.trim() !== '');

    if (!trimmedQuestionText) {
      toast.error('Question text cannot be empty', { position: 'bottom-center' });
      return null;
    }

    if (questionType === 'multiple-choice' && filteredChoices.length === 0) {
      toast.error('Choices cannot be empty for a multiple-choice question', { position: 'bottom-center' });
      return null;
    }

    const Question : PreScreenQuestionBank = {
      question: trimmedQuestionText,
      questionType: questionType,
      choices: questionType === 'multiple-choice' ? filteredChoices : [],
      defaultQuestion: defaultQuestion,
    };

    return Question;
  };

  const  handleAddQuestion = async () => {
    const newQuestionData : PreScreenQuestionBank | null = validateQuestionData(newQuestionText, newQuestionType, choices, newQuestionDefault);

    if (!newQuestionData) return;

    try {
      const response = await createPreScreenQuestion(newQuestionData);
      if (!response.ok) {
        throw new Error();
      }

      const result = await response.json();

      setQuestionsList(prevQuestions => [result, ...prevQuestions]);

      toast.success('Question Added Successfully', { position: 'bottom-center' });

      setNewQuestionText('');
      setChoices(['']);
      setNewQuestion(false);
    } catch (error) {
      toast.error('Error adding question', { position: 'bottom-center' });
    }
  };

  const handleDeleteQuestion = (questionId: string) => {
    setQuestionsList(questionsList.filter(question => question.id !== questionId));
  };

  const handleEditQuestion = (editQuestion: PreScreenQuestionBank) => {
    setQuestionsList(
      questionsList.map(question =>
        question.id === editQuestion.id ? editQuestion: question,
      ),
    );
  };

  return (
    <div className='flex flex-col w-full min-h-[75vh]'>
      <div className='flex w-full gap-4'>
        <div className='flex w-8/12 h-[43px] self-end'>
          <input
              className='w-full placeholder-secondary-grey-200 block px-2 border shadow-sm rounded-md normal-case font-normal text-base leading-6 focus:outline-none focus:border-primary-blue-500'
              type='text'
              placeholder='Search Questions'
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
          />
        </div>
        <div className='w-4/12 self-end'>
          <CustomSelect
              name='questionType'
              placeholder='Select Question Type'
              value={questionTypeOptions.find(option => option.value === questionType)}
              options={questionTypeOptions}
              onChange={(selectedOption: SelectOptions | null) =>
                setQuestionType(selectedOption ? (selectedOption.value as 'numeric' | 'multiple-choice') : null)
              }
              clearable
          />
        </div>
        <div className='w-28 self-end'>
          <button
              onClick={() => setNewQuestion(!newQuestion)}
              className='flex justify-center gap-3 font-semibold border border-[#C6E4E2] p-[9px] px-5 w-full rounded-md text-[#56C2B5] hover:bg-[#E2F0EE]'
          >
            <AddIcon/> New
          </button>
        </div>
      </div>
      { newQuestion && (
        <div className='mt-5 rounded-xl bg-green-50 p-5'>
          <div className='flex gap-4'>
            <div className='w-8/12'>
              <TextInput
                  label='Question'
                  name='question'
                  value={newQuestionText}
                  type='text'
                  onChange={(e) => setNewQuestionText(e.target.value)}
              />
            </div>
            <div className='w-4/12'>
              <CustomSelect
                  label='Question Type'
                  name='questionType'
                  value={questionTypeOptions.find(option => option.value === newQuestionType)}
                  options={questionTypeOptions}
                  onChange={(selectedOption: SelectOptions) => setNewQuestionType(selectedOption.value as PreScreenQuestionType)}
              />
            </div>
          </div>
          { newQuestionType === 'multiple-choice' && (
            <div className='mt-2'>
              { choices.map((choice, index) => (
                <div key={index} className='flex'>
                  <div className='w-full mt-[6px]'>
                    <TextInput
                        name='choice'
                        value={choice}
                        type='text'
                        onChange={(e) => handleChoiceChange(index, e.target.value)}
                    />
                  </div>
                  <div>
                    { index === choices.length - 1 && (
                      <Button
                          onClick={() => setChoices([...choices, ''])}
                          variant='secondary'
                          size='small'
                      >
                        Add Choice
                      </Button>
                    ) }
                  </div>
                </div>
              )) }
            </div>
          ) }
          <div className='w-full flex justify-between items-center'>
            <div className='flex gap-2 items-center'>
              <input
                  type='checkbox'
                  className='w-5 h-5'
                  onChange={() => {
                    setNewQuestionDefault(!newQuestionDefault);
                  }}
                  checked={newQuestionDefault}
              />
              <div className='text-xs'>
                Set As Default
              </div>
            </div>
            <div>
              <Button
                  onClick={() => handleAddQuestion()}
                  variant='primary'
                  size='xSmall'
              >
                Add Question
              </Button>
            </div>
          </div>
        </div>
      ) }
      <div className='flex-grow mt-5'>
        <div className='flex flex-col'>
          { loading ? (
            <TableSkeletonLoader numRows={9} height={12}/>
          ) : (
            <div>
              { questionsList.map((question, indexNum) => (
                <PreScreenQuestion question={question}
                    deleteQuestion={handleDeleteQuestion}
                    editQuestion={handleEditQuestion} key={indexNum}
                    validateQuestion={validateQuestionData}
                />
              )) }
            </div>
          ) }
        </div>
      </div>
      <div className='mt-5'>
        <PaginationNavigator pageNumber={pageNumber} maxPageNumber={totalPages} setPageNumber={setPageNumber}/>
      </div>
    </div>
  );
};
