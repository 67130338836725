import classNames from 'classnames';
import React from 'react';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import styles from './tabs.module.css';

type TabsProp = {
    tabTitles: string[],
    setTabTitles?: (titles: string[]) => void | undefined;
    activeTab: number,
    setActiveTab: (value: number) => void,
    addClose?: boolean,
}

export const Tabs = ({
  tabTitles,
  setTabTitles,
  activeTab,
  setActiveTab,
  addClose = false,
}: TabsProp) => {

  const handleRemoveTab = (index: number) => {
    const newTabs = tabTitles.filter((_, i) => i !== index);
    if (setTabTitles !== undefined){
      setTabTitles(newTabs);
    }
  };

  return (
    <div
        className='flex border-b border-gray-200'
    >
      { tabTitles.map((item: string, index) => {
        return (
          <div
              className={classNames(styles.tabButton, { [styles.selected]: activeTab === index })}
              onClick={() => setActiveTab(index)}
              key={item}
          >
            <div className='flex'>
              <SecondaryTypography.Small
                  fontWeight='semi-bold'
              >
                { item }
              </SecondaryTypography.Small>
              { addClose && setTabTitles && index!==0 &&
                <div className='ml-2' onClick={() => handleRemoveTab(index)}>
                  <svg
                      className='w-4 h-4'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </div>
              }
            </div>
          </div>
        );
      }) }
    </div>
  );
};
