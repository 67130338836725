import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../../../ui/loader/loader';
import { Modal } from '../../../../ui/modal/modal';
import { getAssessmentsByUserId, navigateNextQuestion, startAssessment, startPracticeAssessment, uploadQuestionAnswer } from '../../../../utils/api_service/assessment_api';
import { AssessmentQuestionInfo, UserAssessment, UserAssessmentResponse } from '../../../../utils/types/assessment_types';
import { InputDetails } from '../../../../utils/types/utility_types';
import { AssessmentComplete } from './assessment_complete/assessment_complete';
import styles from './assigned_assessments.module.css';
import { AssessmentInstructionsModal } from './modal_contents/assessment_instructions_modal/assessment_instructions_modal';
import { AssignedListModal } from './modal_contents/assigned_list_modal/assigned_list_modal';
import { EnableCamMicModal } from './modal_contents/enable_cam_mic_modal/enable_cam_mic_modal';
import { InputSettingsModal } from './modal_contents/input_settings_modal/input_settings_modal';
import { SelectedAssessmentModal } from './modal_contents/selected_assessment_modal/selected_assessment_modal';
import PreScreenPerform from './PreScreenPerform';
import { Question } from './question/question';

export const IS_IOS = /iPhone|iPod|iPad/.test(navigator.userAgent);

export const AssignedAssessments = () => {
  const [userAssessmentList, setUserAssessmentList] = useState<UserAssessment[]>([]);
  const [selectedUserAssessment, setSelectedUserAssessment] = useState<UserAssessment>();
  const [responseUserAssessment, setResponseUserAssessment] = useState<UserAssessmentResponse>();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [videoChunks, setVideoChunks] = useState<Blob[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [isPractice, setIsPractice] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<AssessmentQuestionInfo>();
  const [currentAnswer, setCurrentAnswer] = useState<string | File>();
  const [recordedVideo, setRecordedVideo] = useState<string | undefined>();
  const [assessmentComplete, setAssessmentComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(0);
  const [runTimer, setRunTimer] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>();
  const [inputDetails, setInputDetails] = useState<InputDetails>({ cameraId: undefined, micId: undefined });
  const videoEl = useRef<HTMLVideoElement>(null);
  const history = useHistory();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get('practice');
    if (term) {
      setModalContent(4);
    }
    if (runTimer) {
      setCountDown(currentQuestion?.allowedTime || 300);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [runTimer, currentQuestion?.allowedTime]);

  useEffect(() => {
    if (countDown < 0 && runTimer && currentQuestion?.id) {
      setRunTimer(false);
      setCountDown(0);
      setLoading(true);
      resetTimer();
      let videoFile = new File(videoChunks, 'sample.webm');
      handleQuestionSubmission(videoFile, true);
    } else {
      setRemainingTime(countDown);
    }
  }, [countDown, runTimer, currentQuestion?.id, userAssessmentList]);

  const resetTimer = () => setRunTimer((currentValue) => !currentValue);

  useEffect(() => {
    let userId = localStorage.getItem('id');
    if (userId) {
      getAssessmentsByUserId(userId).then(response => {
        const assessmentsToAdd: UserAssessment[] = [];
        if (response.length > 0) {
          response.forEach((assessment: UserAssessment) => {
            if (assessment.assessmentUser.endTime === undefined) {
              assessmentsToAdd.push(assessment);
            }
          });
        }
        setUserAssessmentList(assessmentsToAdd);
      });
    }
  }, []);

  useEffect(() => {
    if (!videoEl) {
      return;
    }

    if (!isModalOpen) {
      navigator.mediaDevices.getUserMedia({ audio: {
        deviceId: inputDetails.micId,
      },
      video: {
        deviceId: inputDetails.cameraId,
      } }).then((mediaStream) => {
        setMediaRecorder(new MediaRecorder(mediaStream));
        let video = videoEl.current;
        if (video) {
          video.muted = true;
          video.srcObject = mediaStream;
          video.autoplay = true;
        }
      });
    }
  }, [videoEl, isModalOpen]);

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          setVideoChunks((videoChunks) => [
            ...videoChunks,
            event.data,
          ]);
        }
      };
    }
    startRecording();
  }, [mediaRecorder]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleGoFullScreen = () => {
    if (elementRef.current) {
      if (elementRef.current.requestFullscreen) {
        elementRef.current.requestFullscreen();
      }
    }
  };

  const handleExitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const shouldCheckPreScreen = () => {
    if (!selectedUserAssessment || !selectedUserAssessment.assessment || !selectedUserAssessment.assessment.preScreen) {
      return false;
    }

    if (!Array.isArray(selectedUserAssessment.assessment.preScreen) || selectedUserAssessment.assessment.preScreen.length === 0) {
      return false;
    }

    return !selectedUserAssessment.assessmentUser.preScreenCompleted;
  };

  const handleModalToggle = () => {
    setIsModalOpen(false);
    history.push('/home');
  };

  const startRecording = () => {
    if (mediaRecorder) {
      setVideoChunks([]);
      mediaRecorder.start(10);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(undefined);
    }
  };

  const initiateAssessment = (assessmentUser:UserAssessmentResponse) => {
    if (assessmentUser.startTime === undefined) {
      startAssessment(assessmentUser.id).then(response => {
        setCurrentQuestion(response);
        setLoading(false);
        resetTimer();
      });
    } else {
      navigateNextQuestion(assessmentUser.id).then(response => {
        setLoading(false);
        if (response.id !== 0) {
          setCurrentQuestion(response);
          resetTimer();
        } else {
          setAssessmentComplete(true);
          handleExitFullScreen();
        }
      });
    }
  };

  const finishedPreScreen = (userAssessment: UserAssessment) => {
    setSelectedUserAssessment(userAssessment);
    commenceAssessment();
  };

  const functionReturn = () => {
    if (shouldCheckPreScreen()) {
      setIsModalOpen(false);
    } else {
      commenceAssessment();
    }
  };

  const commenceAssessment = () => {
    setIsModalOpen(false);
    setLoading(true);
    handleGoFullScreen();
    if (isPractice) {
      startPracticeAssessment().then(response => {
        setResponseUserAssessment(response);
        initiateAssessment(response);
      });
    } else if (selectedUserAssessment) {
      setResponseUserAssessment(selectedUserAssessment.assessmentUser);
      initiateAssessment(selectedUserAssessment.assessmentUser);
    }
  };

  const nextQuestion = (skip: boolean | null | undefined | void = false) => {
    stopRecording();
    let videoFile = new File(videoChunks, 'sample.webm');
    if (skip && currentQuestion) {
      setLoading(true);
      resetTimer();
      uploadQuestionAnswer(currentQuestion.id, 'true', '').then(response => {
        handleAnswerUploadResponse(response);
      });
      return;
    }
    handleQuestionSubmission(videoFile, false);
  };

  const handleQuestionSubmission = (video: File, timeExpired: boolean) => {
    if (typeof currentAnswer === 'string' && currentQuestion) {
      setLoading(true);
      resetTimer();
      uploadQuestionAnswer(currentQuestion.id, timeExpired.toString(), currentAnswer, video).then(response => {
        handleAnswerUploadResponse(response);
      });
    } else if (currentAnswer instanceof Blob && currentQuestion){
      setLoading(true);
      resetTimer();
      uploadQuestionAnswer(currentQuestion.id, timeExpired.toString(), 'Sample', currentAnswer).then(response => {
        handleAnswerUploadResponse(response);
      });
    } else if (timeExpired && currentQuestion) {
      setLoading(true);
      resetTimer();
      uploadQuestionAnswer(currentQuestion.id, timeExpired.toString(), '').then(response => {
        handleAnswerUploadResponse(response);
      });
    }
  };

  const handleAnswerUploadResponse = (response: any) => {
    if (response.ok) {
      setCurrentAnswer(undefined);
      setRecordedVideo(undefined);
      setLoading(false);
      if (responseUserAssessment) {
        navigateNextQuestion(responseUserAssessment.id).then(response => {
          if (response.id !== 0) {
            setCurrentQuestion(response);
            resetTimer();
          } else {
            setAssessmentComplete(true);
            handleExitFullScreen();
          }
        });
      }
    }
  };

  const handlePracticeCompletion = () => {
    setIsPractice(false);
    setIsModalOpen(true);
    setModalContent(0);
    setAssessmentComplete(false);
    handleExitFullScreen();
  };

  const loadModalContent = () => {
    if (modalContent === 0) {
      return (
        <AssignedListModal
            userAssessmentList={userAssessmentList}
            selectedUserAssessment={selectedUserAssessment}
            setSelectedUserAssessment={setSelectedUserAssessment}
            setModalContent={setModalContent}
        />
      );
    } else if (modalContent === 1) {
      return (
        <SelectedAssessmentModal
            selectedAssessment={selectedUserAssessment}
            setModalContent={setModalContent}
        />
      );
    } else if (modalContent === 2) {
      return (
        <EnableCamMicModal
            setModalContent={setModalContent}
        />
      );
    } else if (modalContent === 4) {
      return (
        <AssessmentInstructionsModal
            setModalContent={setModalContent}
            setIsPractice={setIsPractice}
            userAssessmentList={userAssessmentList}
        />
      );
    } else {
      return (
        <InputSettingsModal
            commenceAssessment={functionReturn}
            inputDetails={inputDetails}
            setInputDetails={setInputDetails}
        />
      );
    }
  };

  const loadModalHeaderText = () => {
    if (modalContent === 1 && selectedUserAssessment) {
      return selectedUserAssessment.assessment.assessmentTitle;
    } else if (modalContent === 2) {
      return 'Allow microphone & camera access';
    } else if (modalContent === 3) {
      return 'System setup';
    } else if (modalContent === 4) {
      return 'First Time User Instructions';
    } else {
      return '';
    }
  };

  const renderQuestion = () => {
    if (shouldCheckPreScreen()) {
      return <PreScreenPerform
          preScreenQuestions={selectedUserAssessment?.assessment.preScreen ? selectedUserAssessment?.assessment.preScreen : []}
          selectedUserAssessment={selectedUserAssessment}
          finishedPreScreen={finishedPreScreen}/>;
    }

    if (!assessmentComplete) {
      return (
        <Question
            selectedAssessment={selectedUserAssessment}
            videoElementRef={videoEl}
            currentQuestion={currentQuestion}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            nextQuestion={nextQuestion}
            recordedVideo={recordedVideo}
            setRecordedVideo={setRecordedVideo}
            timeRemaining={remainingTime}
            isPractice={isPractice}
        />
      );
    } else if (assessmentComplete && isPractice) {
      handlePracticeCompletion();
    } else {
      return <AssessmentComplete/>;
    }
  };

  return (
    <div
        className='w-full h-screen'
    >
      <div className={`h-full w-full  ${isFullScreen && 'flex justify-center items-center bg-white px-20'}`} ref={elementRef}>
        { !isModalOpen && renderQuestion() }
      </div>
      <Modal
          visible={isModalOpen}
          toggle={handleModalToggle}
          modalContent={loadModalContent()}
          customClass={styles.modalCustomClass}
          headerClass={classNames(styles.headerBackground, { [styles.headerBackgroundImage]: ![2, 4].includes(modalContent) })}
          headerText={loadModalHeaderText()}
      />
      <Loader
          loading={loading}
      />
    </div>
  );
};
