import React, { useState } from 'react';
import { Button } from '../../../../ui/button/button';
import { ChevronDown } from '../../../../ui/icons/icons';
import { PaginationNavigator } from '../../../../ui/PaginationNavigator';
import { FilterState, JobPostingData } from '../../../../utils/types/JobOpenings';
import JobPostingFilter from '../JobPostingFilter';

const JobPostTable = () => {

  const [filters, setFilters] = useState<FilterState>({
    department: [],
    country: [],
    state: [],
    city: [],
    remote: [],
  });

  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Relevant');

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFilters({
      ...filters,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    });
  };

  const jobData: JobPostingData[] = [
    {
      id: '123456789',
      jobTitle: 'Senior Financial Accountant',
      department: 'Finance',
      state: 'Colombo, Srilanka',
      description: 'This is the description for Senior Financial Accountant.',
    },
    {
      id: '123456789',
      jobTitle: 'Full Stack Developer',
      department: 'Engineering',
      state: 'Melbourne, Australia',
      description: 'This is the description for Full Stack Developer.',
    },
    {
      id: '',
      jobTitle: 'Full Stack Developer',
      department: '',
      state: '',
      description: 'This is the description for another Full Stack Developer.',
    },
    {
      id: '123456789',
      jobTitle: 'Payroll Officer',
      department: 'Finance',
      state: 'New York, USA',
      description: 'This is the description for Payroll Officer.',
    },
    {
      id: '123456789',
      jobTitle: 'Marketing Director',
      department: 'Marketing',
      state: 'London, England',
      description: 'This is the description for Marketing Director.',
    },
  ];

  const splitState = (state: string) => {
    const parts = state.split(', ');
    const lastPart = parts.pop(); // Get the last element (country)
    const firstPart = parts.join(', '); // Join the remaining parts
    return { firstPart, lastPart };
  };

  const handleApply = (id: string) => {
    console.log(`Applying for job with id: ${id}`);
  };

  const toggleExpand = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div>
      <div className='p-4'>
        <input
            type='text'
            placeholder='Search Job Posts by Keyword or Location eg. Senior Accountant, New York'
            className='w-full placeholder-opacity-80 text-opacity-80  text-center py-2 placeholder-white text-white bg-green-500 border border-green-500 rounded focus:outline-none focus:ring-2 focus:ring-green-500 mb-4'
        />
        <div>
          <JobPostingFilter jobData={jobData} onFilterChange={setFilters}/>
        </div>
        <div className='bg-gray-100'>
          <div className='px-6 flex justify-between items-center'>
            <span className='text-sm'>{ jobData.length } Results</span>
            <div className='flex gap-2'>
              <span className='flex text-sm items-center '>Sort By </span>
              <span
                  onClick={toggleDropdown}
                  className='flex text-sm items-center cursor-pointer hover:font-semibold'
              >
                { selectedOption } <ChevronDown/>
              </span>
              { isDropdownOpen && (
                <div className='absolute right-0 mt-5 w-38 bg-white border border-gray-300 rounded shadow-lg'>
                  <ul className='py-1'>
                    <li
                        onClick={() => handleOptionClick('Relevant')}
                        className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                    >
                      Relevant
                    </li>
                    <li
                        onClick={() => handleOptionClick('Latest Jobs')}
                        className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                    >
                      Latest Jobs
                    </li>
                  </ul>
                </div>
              ) }
            </div>
          </div>
          <div className='flex justify-center bg-gray-100 p-4'>
            <div className='overflow-x-auto max-w-6xl w-full'>
              <table className='w-full divide-y divide-gray-200'>
                <tbody className='bg-white divide-y divide-gray-200'>
                  { jobData.map((job, index) => {
                    const { firstPart, lastPart } = splitState(job.state);
                    const isExpanded = expandedRow === index;

                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className='px-6 py-1 whitespace-nowrap text-sm text-gray-900'>
                            <div className='flex items-center'>
                              <button
                                  onClick={() => toggleExpand(index)}
                                  className='mr-2 focus:outline-none'
                              >
                                <svg
                                    className={`w-4 h-4 transform ${isExpanded ? 'rotate-180' : ''}`}
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth={2}
                                      d='M19 9l-7 7-7-7'
                                  />
                                </svg>
                              </button>
                              <span>{ job.jobTitle }</span>
                            </div>
                          </td>
                          <td className='px-1 py-4 whitespace-nowrap text-sm text-gray-900'>
                            <span className='text-gray-400'>Job Post ID</span>
                            <br />
                            <br />
                            { job.id }
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                            <span className='text-gray-400'>Location</span>
                            <br />
                            <br />
                            { firstPart && <span>{ firstPart },</span> }
                            <br />
                            <span>{ lastPart }</span>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
                            <span className='text-gray-400'>Department</span>
                            <br />
                            <br />
                            { job.department }</td>
                          <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                            <Button
                                onClick={()=> handleApply}
                                variant={'primary'}
                                size={'medium'}
                            >
                              Apply
                            </Button>
                          </td>
                        </tr>
                        { isExpanded && (
                          <tr>
                            <td className='px-6 py-4 text-sm text-left text-gray-700 bg-gray-100'>
                              { job.description }
                              <br />
                              click for more details
                            </td>
                          </tr>
                        ) }
                      </React.Fragment>
                    );
                  }) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        { (jobData.length > 0) && (
          <div className='ml-auto mr-auto mt-5'>
            <PaginationNavigator
                pageNumber={pageNumber}
                maxPageNumber={1}
                setPageNumber={setPageNumber}
            />
          </div>
        ) }
      </div>
    </div>
  );
};

export default JobPostTable;
