import React, { Dispatch, useEffect, useState } from 'react';
import { JobDescription, showAllTypes } from '../../../../../../utils/types/JobOpenings';
import AddRemoveComponent from '../../../ui/AddOrRemoveList'; // Adjust the import path as needed
import Collapsible from '../../../ui/CollapsibleChildren';

interface CollapsibleData {
  id: number;
  title: string;
  labels: string[];
  isOpen: boolean;
}

type ListFieldsProps = {
  showAll: showAllTypes;
  setShowAll: React.Dispatch<React.SetStateAction<showAllTypes>>;
  jobDescription: JobDescription,
  setJobDescription: Dispatch<React.SetStateAction<JobDescription>>,
  disabled?: boolean,
}

const ListFields = ({
  showAll,
  setShowAll,
  jobDescription, setJobDescription,
  disabled = false,
}: ListFieldsProps) => {
  const [collapsible, setCollapsible] = useState<CollapsibleData[]>([
    { id: 1, title: 'Responsibilities', labels: jobDescription.responsibilities ? jobDescription.responsibilities : [], isOpen: true },
    { id: 2, title: 'Educations', labels: jobDescription.educations ? jobDescription.educations : [], isOpen: true },
    { id: 3, title: 'Certifications', labels: jobDescription.certifications ? jobDescription.certifications : [], isOpen: true },
    { id: 4, title: 'Mandatory Skills', labels: jobDescription.mandatorySkills ? jobDescription.mandatorySkills : [], isOpen: true },
    { id: 5, title: 'Desirable Skills', labels: jobDescription.desirableSkills ? jobDescription.desirableSkills : [], isOpen: true },
  ]);

  useEffect(() => {
    let newIsOpenState : boolean;
    if (showAll === 'showAll') {
      newIsOpenState = true;
    } else if (showAll === 'collapseAll') {
      newIsOpenState = false;
    } else {
      return;
    }

    setCollapsible(collapsible.map(collapsibleItem => ({
      ...collapsibleItem,
      isOpen: newIsOpenState,
    })));
  }, [showAll]);

  useEffect(() => {
    setJobDescription({ ...jobDescription, responsibilities: collapsible[0].labels, educations: collapsible[1].labels, certifications: collapsible[2].labels, mandatorySkills: collapsible[3].labels, desirableSkills: collapsible[4].labels });
  }, [collapsible]);

  const toggleOpen = (id: number) => {
    const newCollapsible = collapsible.map(collapsible =>
      collapsible.id === id ? { ...collapsible, isOpen: !collapsible.isOpen } : collapsible,
    );
    setCollapsible(newCollapsible);
    const areAllOpen = newCollapsible.every(collapsible => collapsible.isOpen);
    const areAllClosed = newCollapsible.every(collapsible => !collapsible.isOpen);
    if (areAllOpen) {
      setShowAll('showAll');
    } else if (areAllClosed) {
      setShowAll('collapseAll');
    } else {
      setShowAll('random');
    }
  };

  const handleLabelsChange = (id: number, updatedLabels: string[]) => {
    setCollapsible(
      collapsible.map(collapsible =>
        collapsible.id === id ? { ...collapsible, labels: updatedLabels } : collapsible,
      ),
    );
  };

  return (
    <div>
      { collapsible.map(({ id, title, labels, isOpen }, index) => (
        <div key={id} className={`mb-4 ${index === collapsible.length - 1 ? '' : 'mb-8'}`}>
          <Collapsible
              title={title}
              isOpen={isOpen}
              toggleOpen={() => toggleOpen(id)}
          >
            <AddRemoveComponent
                initialLabels={labels}
                disabled={disabled}
                onLabelsChange={(updatedLabels) => handleLabelsChange(id, updatedLabels)}
            />
          </Collapsible>
        </div>
      )) }
    </div>
  );
};

export default ListFields;
