import React from 'react';

type PDFDisplayProps = {
    pdfUrl: string | undefined,
}

export const PDFDisplay = ({
  pdfUrl,
}:PDFDisplayProps) => {

  return (
    <div className='bg-black w-full'>
      <embed src={`${pdfUrl}#toolbar=0`} type='application/pdf' width='100%' height='730px' />
    </div>
  );
};
