import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { TextArea } from '../../../../../ui/input/text_area/text_area';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { ToggleInput } from '../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import {
  createAssessmentSettings,
  getAssessmentSettings,
  updateAssessmentSettings,
} from '../../../../../utils/api_service/assessment_api';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import usePermissions from '../../../../../utils/hooks/usePermissions';
import { AssessmentSettings, AssessmentType } from '../../../../../utils/types/assessment_types';
import commonStyles from '../../view.module.css';

type SettingsProps = {
  assessmentType: AssessmentType,
  assessmentId?: string,
}

export const Settings = ({
  assessmentType,
  assessmentId,
}: SettingsProps) => {
  const { id } = useParams<{id: string}>();
  const { hasPermissions } = usePermissions();
  const [settingsExist, setSettingsExist] = useState<boolean>(false);
  const [settings, setSettings] = useState<AssessmentSettings>({
    assessmentId: assessmentId || id,
    showScore: false,
    customMessage: '',
    shuffleQuestions: false,
    enableCopy: false,
    attemptCount: '1',
    blurVideo: false,
    unsuccessfulEmail: false,
  });

  useEffect(() => {
    getAssessmentSettings(assessmentId || id).then((response: AssessmentSettings) => {
      if (response.assessmentId) {
        setSettingsExist(true);
        setSettings({
          ...response,
          attemptCount: response.attemptCount.toString(),
        });
      }
    });
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (settingsExist) {
      updateAssessmentSettings(assessmentId || id, settings).then(response => {
        if (response.ok) {
          toast.success('Assessment Settings Added!', { position: 'bottom-center' });
        }
      });
    } else {
      createAssessmentSettings(settings).then(response => {
        if (response.ok) {
          toast.success('Assessment Settings Updated!', { position: 'bottom-center' });
        }
      });
    }
  };

  return (
    <div
        style={{ margin: '10px 0' }}
    >
      <SecondaryTypography.Small
          fontWeight='semi-bold'
      >
        Show score at end of test
      </SecondaryTypography.Small>
      <ToggleInput
          toggled={settings.showScore}
          handleToggle={() => setSettings({
            ...settings,
            showScore: !settings.showScore,
          })}
          name='showScore'
          informationText='Show total score'
          disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
      />
      <TextArea
          label='Show this custom message at end of test'
          name='customMessage'
          value={settings.customMessage}
          onChange={handleInputChange}
          disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
      />
      <SecondaryTypography.Small
          fontWeight='semi-bold'
          keepDefaultMargins={true}
      >
        Shuffle questions
      </SecondaryTypography.Small>
      <SecondaryTypography.XSmall>
        This gives you the option to shuffle the questions
        that candidates see on the interface.
      </SecondaryTypography.XSmall>
      <ToggleInput
          toggled={settings.shuffleQuestions}
          handleToggle={() => setSettings({
            ...settings,
            shuffleQuestions: !settings.shuffleQuestions,
          })}
          name='shuffleQuestions'
          informationText='Shuffle question order in assessment'
          disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
      />
      <SecondaryTypography.Small
          fontWeight='semi-bold'
          keepDefaultMargins={true}
      >
        Option to Cut, Copy and Paste
      </SecondaryTypography.Small>
      <SecondaryTypography.XSmall>
        This gives you the option to restrict candidates and
        team members from cut/copy/paste options when attending the assessment.
      </SecondaryTypography.XSmall>
      <ToggleInput
          toggled={settings.enableCopy}
          handleToggle={() => setSettings({
            ...settings,
            enableCopy: !settings.enableCopy,
          })}
          name='enableCopy'
          informationText='Restrict cut/copy/paste during assessment'
          disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
      />
      <SecondaryTypography.Small
          fontWeight='semi-bold'
          keepDefaultMargins={true}
      >
        Option to Blur video
      </SecondaryTypography.Small>
      <SecondaryTypography.XSmall>
        This gives you the option blur the video
        to maintain the anonymity of the candidate.
      </SecondaryTypography.XSmall>
      <ToggleInput
          toggled={settings.blurVideo}
          handleToggle={() => setSettings({
            ...settings,
            blurVideo: !settings.blurVideo,
          })}
          name='edit'
          informationText='Blur face of the candidate'
          disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
      />
      <TextInput
          label='Number of attempts'
          name='attemptCount'
          value={settings.attemptCount}
          type='number'
          onChange={handleInputChange}
          min='1'
          max='10'
          disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
      />
      { assessmentType === 'candidate' &&
        <>
          <ToggleInput
              toggled={settings.unsuccessfulEmail || false}
              handleToggle={() => setSettings({
                ...settings,
                unsuccessfulEmail: !settings.unsuccessfulEmail,
              })}
              name='unsuccessfulEmail'
              informationText='Automatic Unsuccessful Email'
          />
          <TextInput
              label='Threshold Percentage (%)'
              name='thresholdPercent'
              value={settings.thresholdPercent || '0'}
              type='number'
              onChange={handleInputChange}
              min='1'
              max='100'
          />
        </>
      }
      <PermissionGate
          requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
      >
        <Button
            onClick={handleSubmit}
            variant='primary'
            size='medium'
            customClass={commonStyles.customButton}
        >
          Save Changes
        </Button>
      </PermissionGate>
    </div>
  );
};
