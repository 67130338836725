import React  from 'react';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import commonStyles from '../questions.module.css';
import { PreScreenBankSearch } from './PreScreenSearch';

export const PreScreenBank = () => {
  return (
    <div
        className={commonStyles.container}
    >
      <PrimaryTypography.TwoXLarge
          keepDefaultMargins={true}
          fontWeight='bold'
      >
        Pre Screen Question bank
      </PrimaryTypography.TwoXLarge>
      <SecondaryTypography.Small
          keepDefaultMargins={true}
      >
        You can view all the pre screen questions that are used in all the assessments.
      </SecondaryTypography.Small>
      <div
          className={commonStyles.contentContainer}
      >
        <PreScreenBankSearch />
      </div>
    </div>
  );
};
